<template>
  <v-form ref="form">
    <v-row class="mb-4">
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <h2>{{ $t('worker.worker_detail') }}</h2>
          <v-btn color="primary" @click="saveChange" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Worker Account  -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.worker_account') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.worker_id')"
            v-model="worker_account.id"
            filled
            outlined
            readonly
            hide-details
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.email')"
            v-model="worker_account.email"
            outlined
            readonly
            filled
            hide-details
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.registration_date')"
            v-model="worker_account.create_date"
            outlined
            readonly
            filled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.rating')"
            outlined
            readonly
            filled
            hide-details
            v-model="worker_account.rating"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.location')"
            :items="worker_account_list_item.location"
            v-model="worker_account.location"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.working_hour_support')"
            :items="worker_account_list_item.working_hour_support"
            v-model="worker_account.working_hour_support"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.media_to_know')"
            :items="worker_account_list_item.media_to_know"
            v-model="worker_account.media_to_know"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.status')"
            :items="worker_account_list_item.worker_status"
            v-model="worker_account.status"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.approval_status')"
            :items="worker_account_list_item.worker_approval_status"
            :disabled="worker_account.disable_user_approved"
            v-model="worker_account.user_approved"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <!-- Worker Profile -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.worker_profile') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex">
          <v-file-input
            id="profile_photo"
            v-show="false"
            @change="getUploadFile($event, 'profile_photo')"
            accept="image/*"
            outlined
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="worker_profile.profile_pic"
            :label="$t('worker.profile_photo')"
          >
          </v-text-field>
          <v-btn
            color="primary"
            class="ml-6 mt-2"
            depressed
            :loading="submitLoading"
            @click="selectFile('profile_photo')"
          >
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4 mt-2"
            color="info"
            depressed
            :loading="submitLoading"
            :disabled="disable_profile_photo"
            @click="previewFile(worker_profile.profile_pic)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
          <v-btn
            color="error"
            class="ml-4 mt-2"
            depressed
            :disabled="disable_profile_photo"
            @click="removeFile"
            :loading="submitLoading"
          >
            {{ $t('general.remove') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            required
            ref="first_name"
            type="text"
            :label="$t('worker.first_name')"
            v-model="worker_profile.first_name"
            :rules="[FormValidator.required]"
            hide-details="auto"
            outlined
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            required
            ref="last_name"
            type="text"
            :label="$t('worker.last_name')"
            v-model="worker_profile.last_name"
            :rules="[FormValidator.required]"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.nickname')"
            outlined
            hide-details
            v-model="worker_profile.nickname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-select
            :label="$t('worker.gender')"
            :items="gender_list"
            item-text="item-text"
            item-value="item-value"
            v-model="worker_profile.gender"
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-menu
            v-model="date_modal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_birth"
                :label="$t('worker.date_of_birth')"
                :append-icon="icon.calendar_icon"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_of_birth" @input="date_modal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="tel"
            :label="$t('worker.mobile_number')"
            v-model="worker_profile.mobile"
            :rules="[FormValidator.required, FormValidator.isNumberOnly, FormValidator.mobile]"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.residential_address')"
            outlined
            hide-details
            v-model="worker_profile.residential_addr"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.postal_address')"
            outlined
            hide-details
            v-model="worker_profile.postal_addr"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            ref="bio"
            outlined
            hide-details="auto"
            rows="5"
            :label="$t('worker.bio')"
            v-model="worker_profile.bio"
            required
            :rules="[FormValidator.required]"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card>

    <!-- Emergency Contacts -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('worker.emergency_contacts') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addEmergencyContact">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(emergency, index) in emergency_contact_list" :key="index">
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <h4>
                {{ $t('worker.emergency_contacts') }}
                {{ emergency_contact_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeEmergencyContact(index)">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.name')"
              outlined
              v-model="emergency.name"
              hide-details="auto"
              :error="emergency.error.name"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.mobile_number')"
              outlined
              v-model="emergency.mobile"
              hide-details="auto"
              :error-messages="emergency.error.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.email')"
              v-model="emergency.email"
              :error-messages="emergency.error.email"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('worker.relationship')"
              :items="relationship_list"
              v-model="emergency.relationship"
              item-text="item-text"
              item-value="item-value"
              hide-details="auto"
              :error="emergency.error.relationship"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Services -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.services') }}</h3>
        </v-col>
      </v-row>

      <!-- Social Support -->
      <v-row v-ripple @click="service_collapse_list.social_support = !service_collapse_list.social_support">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.social_support') }}</h4>
          <v-icon v-if="service_collapse_list.social_support">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div>
          <v-row v-show="!service_collapse_list.social_support">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(social_support, index) in service_social_support_field"
              :key="index"
            >
              <v-checkbox
                v-if="social_support.value == 'Work Experience' && service_selection.social_support"
                hide-details
                dense
                color="primary"
                :label="$t(social_support.text)"
                value
                input-value="true"
                readonly
              ></v-checkbox>
              <v-checkbox
                v-else
                hide-details
                dense
                color="primary"
                :label="$t(social_support.text)"
                :value="social_support.value"
                v-model="service_social_support"
                @change="checkShowFileInput('social_support', index)"
              ></v-checkbox>
              <v-row v-if="social_support.upload_required && social_support.show_upload_field" class="mt-2">
                <v-col class="d-flex align-center" xl="8" lg="10" md="12" sm="12" cols="12">
                  <v-file-input
                    :id="`social_support_${index}`"
                    v-show="false"
                    accept="image/*,application/pdf"
                    hide-details="auto"
                    dense
                    outlined
                    @change="getUploadFile($event, 'social_support', index)"
                  ></v-file-input>
                  <v-text-field
                    type="text"
                    outlined
                    readonly
                    hide-details="auto"
                    :error="social_support.error"
                    dense
                    v-model="social_support.file"
                    :label="$t('general.upload_document_file')"
                  >
                  </v-text-field>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    depressed
                    small
                    :loading="submitLoading"
                    @click="selectFile(`social_support_${index}`)"
                  >
                    {{ $t('general.upload') }}</v-btn
                  >
                  <v-btn
                    class="ml-4"
                    color="info"
                    depressed
                    small
                    :disabled="social_support.disable_preview"
                    @click="previewFile(social_support.file)"
                  >
                    {{ $t('general.view_detail') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="service_selection_error.social_support">
            <v-col>
              <div class="error_msg">{{ $t('error.item_and_work_experience') }}</div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>

      <!-- Personal Care -->
      <v-row class="mt-12" v-ripple @click="service_collapse_list.personal_care = !service_collapse_list.personal_care">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.personal_care') }}</h4>
          <v-icon v-if="service_collapse_list.personal_care">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div>
          <v-row v-show="!service_collapse_list.personal_care">
            <v-col>
              <v-row>
                <v-col
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  cols="12"
                  v-for="(personal_care_field, index) in service_personal_care_field"
                  :key="index"
                >
                  <v-checkbox
                    hide-details
                    dense
                    color="primary"
                    :label="$t(personal_care_field.text)"
                    :value="personal_care_field.value"
                    v-model="service_personal_care"
                    @change="checkShowFileInput('personal_care', index)"
                    :readonly="
                      service_selection.personal_care && personal_care_field.value == '1 or more years of experience'
                    "
                  ></v-checkbox>

                  <v-row
                    v-if="personal_care_field.upload_required && personal_care_field.show_upload_field"
                    class="mt-2"
                  >
                    <v-col class="d-flex align-center" xl="8" lg="10" md="12" sm="12" cols="12">
                      <v-file-input
                        :id="`personal_care_${index}`"
                        v-show="false"
                        accept="image/*,application/pdf"
                        hide-details="auto"
                        dense
                        outlined
                        @change="getUploadFile($event, 'personal_care', index)"
                      ></v-file-input>
                      <v-text-field
                        type="text"
                        outlined
                        readonly
                        hide-details="auto"
                        :error="personal_care_field.error"
                        dense
                        v-model="personal_care_field.file"
                        :label="$t('general.upload_document_file')"
                      >
                      </v-text-field>
                      <v-btn
                        color="primary"
                        class="ml-4"
                        depressed
                        small
                        :loading="submitLoading"
                        @click="selectFile(`personal_care_${index}`)"
                      >
                        {{ $t('general.upload') }}</v-btn
                      >
                      <v-btn
                        class="ml-4"
                        color="info"
                        depressed
                        small
                        :disabled="personal_care_field.disable_preview"
                        @click="previewFile(personal_care_field.file)"
                      >
                        {{ $t('general.view_detail') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="service_selection_error.personal_care">
                <v-col>
                  <div class="error_msg">{{ $t('error.item_and_experience') }}</div>
                </v-col>
              </v-row>
              <v-row class="mt-6 mb-4">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  cols="12"
                  v-for="(personal_care_field, index) in service_personal_care_field_two"
                  :key="index"
                >
                  <v-checkbox
                    hide-details
                    dense
                    color="primary"
                    :label="$t(personal_care_field.text)"
                    :value="personal_care_field.value"
                    v-model="service_personal_care_two"
                    @change="checkShowFileInput('personal_care_two', index)"
                  ></v-checkbox>
                  <v-row
                    v-if="personal_care_field.upload_required && personal_care_field.show_upload_field"
                    class="mt-2"
                  >
                    <v-col class="d-flex align-center" xl="8" lg="10" md="12" sm="12" cols="12">
                      <v-file-input
                        :id="`personal_care_two_${index}`"
                        v-show="false"
                        accept="image/*,application/pdf"
                        hide-details="auto"
                        dense
                        outlined
                        @change="getUploadFile($event, 'personal_care_two', index)"
                      ></v-file-input>
                      <v-text-field
                        type="text"
                        outlined
                        readonly
                        hide-details="auto"
                        :error="personal_care_field.error"
                        dense
                        v-model="personal_care_field.file"
                        :label="$t('general.upload_document_file')"
                      >
                      </v-text-field>
                      <v-btn
                        color="primary"
                        class="ml-4"
                        depressed
                        small
                        :loading="submitLoading"
                        @click="selectFile(`personal_care_two_${index}`)"
                      >
                        {{ $t('general.upload') }}</v-btn
                      >
                      <v-btn
                        class="ml-4"
                        color="info"
                        depressed
                        small
                        :disabled="personal_care_field.disable_preview"
                        @click="previewFile(personal_care_field.file)"
                      >
                        {{ $t('general.view_detail') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="service_selection_error.personal_care_two">
                <v-col>
                  <div class="error_msg">{{ $t('error.select_at_least_one') }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>

      <!-- Nursing Registration -->
      <v-row
        class="mt-12"
        v-ripple
        @click="service_collapse_list.nursing_registration = !service_collapse_list.nursing_registration"
      >
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.nursing_registration') }}</h4>
          <v-icon v-if="service_collapse_list.nursing_registration">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="!service_collapse_list.nursing_registration">
          <v-col>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-select
                  :label="$t('worker.nursing_type')"
                  :items="worker_nursing_type"
                  item-text="item-text"
                  item-value="item-value"
                  v-model="service_nursing_registration.nursing_type.type"
                  :error="service_nursing_registration.nursing_type.error"
                  hide-details
                  outlined
                ></v-select>
              </v-col>
              <v-col class="d-flex align-center" xl="6" lg="6" md="6" sm="6" cols="12">
                <v-file-input
                  id="nursing_type"
                  v-show="false"
                  accept="image/*,application/pdf"
                  hide-details="auto"
                  dense
                  outlined
                  @change="getUploadFile($event, 'nursing_type')"
                ></v-file-input>
                <v-text-field
                  type="text"
                  outlined
                  readonly
                  hide-details="auto"
                  :error="service_nursing_registration.nursing_type.file_err"
                  v-model="service_nursing_registration.nursing_type.nursing_type_doc"
                  :label="$t('general.upload_document_file')"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  class="ml-4"
                  depressed
                  small
                  :loading="submitLoading"
                  @click="selectFile('nursing_type')"
                >
                  {{ $t('general.upload') }}</v-btn
                >
                <v-btn
                  class="ml-4"
                  color="info"
                  depressed
                  small
                  :disabled="service_nursing_registration.nursing_type.disable_nursing_type_doc"
                  @click="previewFile(service_nursing_registration.nursing_type.nursing_type_doc)"
                >
                  {{ $t('general.view_detail') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-text-field
                  type="text"
                  :label="$t('worker.registration_number')"
                  :error="service_nursing_registration.registration_error"
                  v-model="service_nursing_registration.registration_number"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-dialog
                  ref="dialog"
                  v-model="nursing_expiry_date_modal"
                  :return-value.sync="nursing_expiry_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="nursing_expiry_date"
                      :label="$t('worker.expiry_date')"
                      :append-icon="icon.calendar_icon"
                      :error="nursing_expiry_date_error"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="nursing_expiry_date" :min="max_birth_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="nursing_expiry_date_modal = false">
                      {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(nursing_expiry_date)">
                      {{ $t('general.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="d-flex align-center" xl="6" lg="6" md="6" sm="12" cols="12">
                <v-file-input
                  id="nursing_work_experience"
                  v-show="false"
                  accept="image/*,application/pdf"
                  hide-details="auto"
                  dense
                  outlined
                  @change="getUploadFile($event, 'nursing_work_experience')"
                ></v-file-input>
                <v-text-field
                  type="text"
                  outlined
                  readonly
                  hide-details="auto"
                  :error="service_nursing_registration.work_experience.file_err"
                  v-model="service_nursing_registration.work_experience.work_exp_doc"
                  :label="$t('service_social_support_field.work_experience')"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  class="ml-4"
                  depressed
                  small
                  :loading="submitLoading"
                  @click="selectFile('nursing_work_experience')"
                >
                  {{ $t('general.upload') }}</v-btn
                >
                <v-btn
                  class="ml-4"
                  color="info"
                  depressed
                  small
                  :disabled="service_nursing_registration.work_experience.disable_work_exp_doc"
                  @click="previewFile(service_nursing_registration.work_experience.work_exp_doc)"
                >
                  {{ $t('general.view_detail') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-6 mb-4">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="6"
                cols="12"
                v-for="(nursing, index) in service_nursing_registration.checkbox"
                :key="index"
              >
                <v-checkbox
                  hide-details
                  dense
                  color="primary"
                  :label="$t(nursing.text)"
                  :value="nursing.value"
                  v-model="nursing_checkbox"
                  @change="checkShowFileInput('nursing', index)"
                ></v-checkbox>
                <v-row v-if="nursing.upload_required && nursing.show_upload_field" class="mt-2">
                  <v-col class="d-flex align-center" xl="8" lg="10" md="12" sm="12" cols="12">
                    <v-file-input
                      :id="`nursing_${index}`"
                      v-show="false"
                      accept="image/*,application/pdf"
                      hide-details="auto"
                      dense
                      outlined
                      @change="getUploadFile($event, 'nursing', index)"
                    ></v-file-input>
                    <v-text-field
                      type="text"
                      outlined
                      readonly
                      hide-details="auto"
                      :error="nursing.error"
                      dense
                      v-model="nursing.file"
                      :label="$t('general.upload_document_file')"
                    >
                    </v-text-field>
                    <v-btn
                      color="primary"
                      class="ml-4"
                      depressed
                      small
                      :loading="submitLoading"
                      @click="selectFile(`nursing_${index}`)"
                    >
                      {{ $t('general.upload') }}</v-btn
                    >
                    <v-btn
                      class="ml-4"
                      color="info"
                      depressed
                      small
                      :disabled="nursing.disable_preview"
                      @click="previewFile(nursing.file)"
                    >
                      {{ $t('general.view_detail') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="service_selection_error.nursing_registration">
              <v-col>
                <div class="error_msg">{{ $t('error.select_at_least_one') }}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Allied-Health Services -->
      <v-row class="mt-12" v-ripple @click="service_collapse_list.allied_health = !service_collapse_list.allied_health">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.allied_health') }}</h4>
          <v-icon v-if="service_collapse_list.allied_health">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div>
          <v-row v-if="!service_collapse_list.allied_health">
            <v-col>
              <v-row>
                <v-col
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  cols="12"
                  v-for="(allied, index) in service_allied_health_field"
                  :key="index"
                >
                  <v-checkbox
                    hide-details
                    dense
                    color="primary"
                    :label="$t(allied.text)"
                    :value="allied.value"
                    v-model="allied_health"
                    @change="checkShowFileInput('allied', index)"
                  ></v-checkbox>
                  <div v-if="allied.upload_required && allied.show_upload_field" class="mt-4">
                    <v-row>
                      <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                        <v-text-field
                          type="text"
                          :label="$t('worker.registration_number')"
                          outlined
                          dense
                          hide-details="auto"
                          v-model="allied.registration_number"
                          :error="allied.registration_number_error"
                        ></v-text-field>
                      </v-col>
                      <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                        <v-menu
                          v-model="allied_health_date_modal[index]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="allied_health_date[index]"
                              :label="$t('worker.expiry_date')"
                              :append-icon="icon.calendar_icon"
                              readonly
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              :error="allied.expiry_date_error"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="allied_health_date[index]"
                            @input="allied_health_date_modal[index] = false"
                            :min="max_birth_date"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center" xl="12" lg="12" md="12" sm="12" cols="12">
                        <v-file-input
                          :id="`allied_${index}`"
                          v-show="false"
                          accept="image/*,application/pdf"
                          hide-details="auto"
                          dense
                          outlined
                          @change="getUploadFile($event, 'allied', index)"
                        ></v-file-input>
                        <v-text-field
                          type="text"
                          outlined
                          readonly
                          hide-details="auto"
                          :error="allied.error"
                          dense
                          v-model="allied.file"
                          :label="$t('general.upload_document_file')"
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          class="ml-4"
                          depressed
                          small
                          :loading="submitLoading"
                          @click="selectFile(`allied_${index}`)"
                        >
                          {{ $t('general.upload') }}</v-btn
                        >
                        <v-btn
                          class="ml-4"
                          color="info"
                          depressed
                          small
                          :disabled="allied.disable_preview"
                          @click="previewFile(allied.file)"
                        >
                          {{ $t('general.view_detail') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex align-center" xl="12" lg="12" md="12" sm="12" cols="12">
                        <v-file-input
                          :id="`allied_work_exp_${index}`"
                          v-show="false"
                          accept="image/*,application/pdf"
                          hide-details="auto"
                          dense
                          outlined
                          @change="getUploadFile($event, 'allied_work_exp', index)"
                        ></v-file-input>
                        <v-text-field
                          type="text"
                          outlined
                          readonly
                          hide-details="auto"
                          :error="allied.work_experience.error"
                          dense
                          v-model="allied.work_experience.file"
                          :label="$t('service_social_support_field.work_experience')"
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          class="ml-4"
                          depressed
                          small
                          :loading="submitLoading"
                          @click="selectFile(`allied_work_exp_${index}`)"
                        >
                          {{ $t('general.upload') }}</v-btn
                        >
                        <v-btn
                          class="ml-4"
                          color="info"
                          depressed
                          small
                          :disabled="allied.work_experience.disable_preview"
                          @click="previewFile(allied.work_experience.file)"
                        >
                          {{ $t('general.view_detail') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="service_selection_error.allied_health">
            <v-col>
              <div class="error_msg">{{ $t('error.select_at_least_one') }}</div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>

      <!-- Additional Training -->
      <v-row
        class="mt-12"
        v-ripple
        @click="service_collapse_list.additional_training = !service_collapse_list.additional_training"
      >
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.additional_training') }}</h4>
          <v-icon v-if="service_collapse_list.additional_training">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div>
          <v-row v-if="!service_collapse_list.additional_training">
            <v-col>
              <v-row>
                <v-col
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  cols="12"
                  v-for="(additional, index) in service_additional_training_field"
                  :key="index"
                >
                  <v-checkbox
                    hide-details
                    dense
                    color="primary"
                    :label="$t(additional.text)"
                    :value="additional.value"
                    v-model="additional_training"
                    @change="checkShowFileInput('additional_training', index)"
                  ></v-checkbox>
                  <div v-if="additional.upload_required && additional.show_upload_field" class="mt-4">
                    <v-row>
                      <v-col class="d-flex align-center">
                        <v-file-input
                          :id="`additional_training_${index}`"
                          v-show="false"
                          accept="image/*,application/pdf"
                          hide-details="auto"
                          dense
                          outlined
                          @change="getUploadFile($event, 'additional_training', index)"
                        ></v-file-input>
                        <v-text-field
                          type="text"
                          outlined
                          readonly
                          hide-details="auto"
                          :error="additional.error"
                          dense
                          v-model="additional.file"
                          :label="$t('general.upload_document_file')"
                        >
                        </v-text-field>
                        <v-btn
                          color="primary"
                          class="ml-4"
                          depressed
                          small
                          :loading="submitLoading"
                          @click="selectFile(`additional_training_${index}`)"
                        >
                          {{ $t('general.upload') }}</v-btn
                        >
                        <v-btn
                          class="ml-4"
                          color="info"
                          depressed
                          small
                          :disabled="additional.disable_preview"
                          @click="previewFile(additional.file)"
                        >
                          {{ $t('general.view_detail') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="service_selection_error.additional_training">
            <v-col>
              <div class="error_msg">{{ $t('error.select_at_least_one') }}</div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card>

    <!-- Verifications -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.verifications') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.first_name')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.first_name"
            :rules="[FormValidator.required]"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.last_name')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.last_name"
            :rules="[FormValidator.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.how_do_you_know_the_referee')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.know_referee"
            :rules="[FormValidator.required]"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.how_long_have_known_your_referee')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.known_long"
            :rules="[FormValidator.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            type="text"
            :label="$t('worker.email')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.email"
            :rules="[FormValidator.required, FormValidator.email]"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.mobile_number')"
            outlined
            required
            hide-details="auto"
            v-model="verifications.mobile"
            :rules="[FormValidator.required, FormValidator.mobile, FormValidator.isNumberOnly]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.company')"
            outlined
            hide-details="auto"
            v-model="verifications.company"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            hide-details="auto"
            dense
            color="primary"
            :label="$t('worker.covid_19_training')"
            v-model="verifications.covid_training.checked"
          ></v-checkbox>

          <v-row class="mt-2" v-if="verifications.covid_training.checked">
            <v-col class="d-flex align-center">
              <v-file-input
                id="covid_19_training"
                v-show="false"
                accept="image/*,application/pdf"
                hide-details="auto"
                dense
                outlined
                @change="getUploadFile($event, 'covid_19_training')"
              ></v-file-input>
              <v-text-field
                type="text"
                outlined
                readonly
                hide-details="auto"
                :error="verifications.covid_training.error"
                dense
                v-model="verifications.covid_training.covid_training_file"
                :label="$t('general.upload_document_file')"
              >
              </v-text-field>
              <v-btn
                color="primary"
                class="ml-4"
                depressed
                small
                :loading="submitLoading"
                @click="selectFile('covid_19_training')"
              >
                {{ $t('general.upload') }}</v-btn
              >
              <v-btn
                class="ml-4"
                color="info"
                depressed
                small
                :disabled="verifications.covid_training.disable_preview"
                @click="previewFile(verifications.covid_training.covid_training_file)"
              >
                {{ $t('general.view_detail') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            hide-details="auto"
            dense
            color="primary"
            :label="$t('worker.sexual_conviction_record')"
            v-model="verifications.sexual_conviction_record.checked"
          ></v-checkbox>
          <div v-if="verifications.sexual_conviction_record.checked" class="mt-4">
            <v-row>
              <v-col class="d-flex align-center">
                <v-file-input
                  id="sexual_conviction_record"
                  v-show="false"
                  accept="image/*,application/pdf"
                  hide-details="auto"
                  dense
                  outlined
                  @change="getUploadFile($event, 'sexual_conviction_record')"
                ></v-file-input>
                <v-text-field
                  type="text"
                  outlined
                  readonly
                  hide-details="auto"
                  :error="verifications.sexual_conviction_record.file_error"
                  dense
                  v-model="verifications.sexual_conviction_record.sexual_conviction_record_file"
                  :label="$t('general.upload_document_file')"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  class="ml-4"
                  depressed
                  small
                  :loading="submitLoading"
                  @click="selectFile('sexual_conviction_record')"
                >
                  {{ $t('general.upload') }}</v-btn
                >
                <v-btn
                  class="ml-4"
                  color="info"
                  depressed
                  small
                  :disabled="verifications.sexual_conviction_record.disable_preview"
                  @click="previewFile(verifications.sexual_conviction_record.sexual_conviction_record_file)"
                >
                  {{ $t('general.view_detail') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <v-text-field
                  type="text"
                  :label="$t('worker.reference_number')"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="verifications.sexual_conviction_record.sexual_conviction_ref"
                  :error="verifications.sexual_conviction_record.ref_error"
                ></v-text-field>
              </v-col>
              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <v-menu
                  v-model="sexual_conviction_date_model"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sexual_conviction_date"
                      :label="$t('worker.expiry_date')"
                      :append-icon="icon.calendar_icon"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      :error="verifications.sexual_conviction_record.date_error"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="sexual_conviction_date"
                    @input="sexual_conviction_date_model = false"
                    :min="max_birth_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Bank Account -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.bank_account') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.account_name')"
            outlined
            hide-details="auto"
            :rules="[FormValidator.required]"
            v-model="bank_acc.account_name"
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.bank_name')"
            outlined
            hide-details="auto"
            :rules="[FormValidator.required]"
            v-model="bank_acc.bank_name"
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.account_number')"
            outlined
            hide-details="auto"
            :rules="[FormValidator.required]"
            v-model="bank_acc.account_number"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Rates and availability -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.rates_and_availability') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.avail_for_work')"
            :items="yes_no_list"
            v-model="rates_availability.avail_for_work"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            multiple
            :label="$t('worker.locations')"
            :items="worker_account_list_item.location"
            v-model="rates_availability.location"
            :rules="[FormValidator.required]"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            multiple
            :label="$t('worker.preferred_days')"
            :items="rates_availability_list_item.preferred_days"
            v-model="rates_availability.preferred_days"
            :rules="[FormValidator.required]"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.meet_and_greet')"
            :items="yes_no_list"
            v-model="rates_availability.meet_greet"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('worker.display_rates') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            hide-details="auto"
            dense
            color="primary"
            :label="$t('worker.weekday')"
            :error="rates_availability.weekday.error"
            v-model="rates_availability.weekday.checked"
          ></v-checkbox>
          <v-row class="mt-2">
            <v-col xl="12" lg="12" md="12" sm="12" cols="12">
              <v-text-field
                type="text"
                :prefix="$t('worker.hourly_rate')"
                outlined
                hide-details="auto"
                v-model="rates_availability.weekday.rate"
                :error="rates_availability.weekday.rate_error"
                :error-messages="rates_availability.weekday.error_msg"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            hide-details="auto"
            dense
            color="primary"
            :label="$t('worker.weekend')"
            :error="rates_availability.weekend.error"
            v-model="rates_availability.weekend.checked"
          ></v-checkbox>
          <v-row class="mt-2">
            <v-col xl="12" lg="12" md="12" sm="12" cols="12">
              <v-text-field
                type="text"
                :prefix="$t('worker.hourly_rate')"
                outlined
                hide-details="auto"
                v-model="rates_availability.weekend.rate"
                :error="rates_availability.weekend.rate_error"
                :error-messages="rates_availability.weekend.error_msg"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            hide-details="auto"
            dense
            color="primary"
            :label="$t('worker.hour_shift')"
            :error="rates_availability.shift.error"
            v-model="rates_availability.shift.checked"
          ></v-checkbox>
          <v-row class="mt-2">
            <v-col xl="12" lg="12" md="12" sm="12" cols="12">
              <v-text-field
                type="text"
                :prefix="$t('worker.flat_rate')"
                outlined
                hide-details="auto"
                v-model="rates_availability.shift.rate"
                :error="rates_availability.shift.rate_error"
                :error-messages="rates_availability.shift.error_msg"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Profile -->
    <!-- <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.profile') }}</h3>
        </v-col>
      </v-row>
    </v-card> -->

    <!-- Work History -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('worker.work_history') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addWorkHistory">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(history, index) in work_history_list" :key="index">
        <v-row>
          <v-col class="pb-0">
            <div class="d-flex align-center">
              <h4>
                {{ $t('worker.work_history') }}
                {{ work_history_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeWorkHistory(index)">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-checkbox
              hide-details="auto"
              dense
              color="primary"
              :label="$t('worker.current_working_job')"
              v-model="history.current_working"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.job_title')"
              outlined
              v-model="history.job_title"
              hide-details="auto"
              :error="history.error.job_title"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.company')"
              outlined
              v-model="history.company"
              hide-details="auto"
              :error="history.error.company"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-menu
              v-model="work_start_date_modal[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="work_start_date[index]"
                  :label="$t('worker.start_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  :error-messages="history.error.start_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="work_start_date[index]"
                @input="work_start_date_modal[index] = false"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-menu
              v-model="work_end_date_modal[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="work_end_date[index]"
                  :label="$t('worker.end_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  :error-messages="history.error.end_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="work_end_date[index]"
                @input="work_end_date_modal[index] = false"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Education and Training -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('worker.education_and_training') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addEducationTraining">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(education, index) in education_training_list" :key="index">
        <v-row>
          <v-col class="pb-0">
            <div class="d-flex align-center">
              <h4>
                {{ $t('worker.education_and_training') }}
                {{ education_training_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeEducationTraining(index)">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-checkbox
              hide-details="auto"
              dense
              color="primary"
              :label="$t('worker.current_working_degree')"
              v-model="education.current_working"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.institution')"
              outlined
              v-model="education.institution"
              hide-details="auto"
              :error="education.error.institution"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.degree_course')"
              outlined
              v-model="education.degree_course"
              hide-details="auto"
              :error="education.error.degree_course"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-menu
              v-model="education_start_date_modal[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="education_start_date[index]"
                  :label="$t('worker.start_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  :error-messages="education.error.start_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="education_start_date[index]"
                @input="education_start_date_modal[index] = false"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-menu
              v-model="education_end_date_modal[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="education_end_date[index]"
                  :label="$t('worker.end_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  :error-messages="education.error.end_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="education_end_date[index]"
                @input="education_end_date_modal[index] = false"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Care Experience -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.care_experience') }}</h3>
        </v-col>
      </v-row>

      <!-- Aged Care -->
      <v-row v-ripple @click="care_experience_collapse_list.aged_care = !care_experience_collapse_list.aged_care">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.aged_care') }}</h4>
          <v-icon v-if="care_experience_collapse_list.aged_care">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-show="!care_experience_collapse_list.aged_care">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(aged_care, index) in care_experience_aged_care_field"
            :key="index"
          >
            <v-checkbox
              hide-details
              dense
              color="primary"
              :label="$t(aged_care.text)"
              :value="aged_care.value"
              v-model="care_experience_aged_care"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Chronic Medical Conditions -->
      <v-row v-ripple @click="care_experience_collapse_list.chronic = !care_experience_collapse_list.chronic">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.chronic') }}</h4>
          <v-icon v-if="care_experience_collapse_list.aged_care">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-show="!care_experience_collapse_list.chronic">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(chronic, index) in care_experience_chronic_field"
            :key="index"
          >
            <v-checkbox
              hide-details
              dense
              color="primary"
              :label="$t(chronic.text)"
              :value="chronic.value"
              v-model="care_experience_chronic"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Disability -->
      <v-row v-ripple @click="care_experience_collapse_list.disability = !care_experience_collapse_list.disability">
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.disability') }}</h4>
          <v-icon v-if="care_experience_collapse_list.disability">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-show="!care_experience_collapse_list.disability">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(disability, index) in care_experience_disability_field"
            :key="index"
          >
            <v-checkbox
              hide-details
              dense
              color="primary"
              :label="$t(disability.text)"
              :value="disability.value"
              v-model="care_experience_disability"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Mental Health -->
      <v-row
        v-ripple
        @click="care_experience_collapse_list.mental_health = !care_experience_collapse_list.mental_health"
      >
        <v-col class="d-flex align-center justify-space-between expansion-card">
          <h4>{{ $t('worker.mental_health') }}</h4>
          <v-icon v-if="care_experience_collapse_list.mental_health">{{ icon.expand_icon }}</v-icon>
          <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-show="!care_experience_collapse_list.mental_health">
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(mental_health, index) in care_experience_mental_health_field"
            :key="index"
          >
            <v-checkbox
              hide-details
              dense
              color="primary"
              :label="$t(mental_health.text)"
              :value="mental_health.value"
              v-model="care_experience_mental_health"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <!-- Badges -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('worker.badges') }}</h3>
        </v-col>
      </v-row>

      <!-- Covid -->
      <v-row>
        <v-col>
          <h4>{{ $t('badges_field.covid') }}</h4>
          <v-radio-group v-model="covid" class="mt-2">
            <v-radio v-for="(val, index) in covid_field" :key="index" :label="$t(val.text)" :value="val.value">
            </v-radio>
          </v-radio-group>
          <div v-if="covid == 'Yes, at least one dose' || covid == 'Yes, fully vaccinated'">
            <v-row>
              <v-col class="d-flex align-center">
                <v-file-input
                  id="badges_covid"
                  v-show="false"
                  accept="image/*,application/pdf"
                  hide-details="auto"
                  dense
                  outlined
                  @change="getUploadFile($event, 'badges_covid', index)"
                ></v-file-input>
                <v-text-field
                  type="text"
                  outlined
                  readonly
                  hide-details="auto"
                  :error="covid_error"
                  dense
                  v-model="covid_file"
                  :label="$t('general.upload_document_file')"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  class="ml-4"
                  depressed
                  small
                  :loading="submitLoading"
                  @click="selectFile('badges_covid')"
                >
                  {{ $t('general.upload') }}</v-btn
                >
                <v-btn
                  class="ml-4"
                  color="info"
                  depressed
                  small
                  :disabled="covid_disable_preview"
                  @click="previewFile(covid_file)"
                >
                  {{ $t('general.view_detail') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!-- Flu -->
      <v-row>
        <v-col>
          <h4>{{ $t('badges_field.flu') }}</h4>
          <v-radio-group v-model="flu" class="mt-2">
            <v-radio v-for="(val, index) in flu_field" :key="index" :label="$t(val.text)" :value="val.value"> </v-radio>
          </v-radio-group>
          <div v-if="flu">
            <v-row>
              <v-col class="d-flex align-center">
                <v-file-input
                  id="badges_flu"
                  v-show="false"
                  accept="image/*,application/pdf"
                  hide-details="auto"
                  dense
                  outlined
                  @change="getUploadFile($event, 'badges_flu', index)"
                ></v-file-input>
                <v-text-field
                  type="text"
                  outlined
                  readonly
                  hide-details="auto"
                  :error="flu_error"
                  dense
                  v-model="flu_file"
                  :label="$t('general.upload_document_file')"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  class="ml-4"
                  depressed
                  small
                  :loading="submitLoading"
                  @click="selectFile('badges_flu')"
                >
                  {{ $t('general.upload') }}</v-btn
                >
                <v-btn
                  class="ml-4"
                  color="info"
                  depressed
                  small
                  :disabled="flu_disable_preview"
                  @click="previewFile(flu_file)"
                >
                  {{ $t('general.view_detail') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!-- LGBTI -->
      <v-row>
        <v-col>
          <h4>{{ $t('badges_field.lgbti') }}</h4>
          <v-radio-group v-model="lgbti" class="mt-2">
            <v-radio v-for="(val, index) in lgbti_field" :key="index" :label="$t(val.text)" :value="val.value">
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="py-0">
          <h4>{{ $t('worker.first_language') }}</h4>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="py-0">
          <h4>{{ $t('worker.cultural_background') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="language_list"
            :label="$t('worker.first_language')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="first_language"
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.cultural_background')"
            outlined
            hide-details="auto"
            v-model="cultural_background"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.other_languages') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="language_list"
            :label="$t('worker.other_languages')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="other_language"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.religion') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="religion_list"
            :label="$t('worker.religion')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="religion"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.interest') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="interest_list"
            :label="$t('worker.interest')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="interest"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.personality') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-select
            :items="personality_list"
            :label="$t('worker.personality_at_work')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="personality"
          ></v-select>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-select
            :items="yes_no_list"
            :label="$t('worker.non_smoker')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="non_smoker"
          ></v-select>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-select
            :items="yes_no_list"
            :label="$t('worker.per_friendly')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="pet_friendly"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.my_preferences') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="prefer_to_work_with_list"
            :label="$t('worker.prefer_to_work_with')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="prefer_work_with"
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="gender_preference_list"
            :label="$t('worker.gender')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="prefer_gender"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col>
        <v-btn color="primary" @click="saveChange" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiCalendar, mdiDelete, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { FormValidator, CheckInputValidate, globalFunc, MEDIA_PATH } from '@/global'
import { getData, postData } from '@/api'

export default {
  name: 'WorkerDetail',
  mixins: [FormValidator, CheckInputValidate, globalFunc],
  data: () => ({
    FormValidator: FormValidator,
    submitLoading: false,
    yes_no_list: [],
    icon: {
      calendar_icon: mdiCalendar,
      delete_icon: mdiDelete,
      expand_icon: mdiChevronDown,
      collapse_icon: mdiChevronUp,
    },
    worker_account: {
      id: '',
      email: '',
      create_date: '',
      rating: '',
      location: '',
      working_hour_support: '',
      media_to_know: '',
      status: '',
      disable_user_approved: false,
      user_approved: '',
    },
    worker_account_list_item: {
      location: [],
      worker_status: [],
      worker_approval_status: [],
      working_hour_support: [],
      media_to_know: [],
    },
    worker_profile: {
      profile_pic: '',
      first_name: '',
      last_name: '',
      nickname: '',
      gender: '',
      mobile: '',
      residential_addr: '',
      postal_addr: '',
      bio: '',
    },
    gender_list: [],
    disable_profile_photo: true,
    date_modal: false,
    date_of_birth: '',
    max_birth_date: '',
    relationship_list: [],
    worker_nursing_type: [],
    emergency_contact_list: [],
    service_collapse_list: {
      social_support: true,
      personal_care: true,
      nursing_registration: true,
      allied_health: true,
      additional_training: true,
    },
    service_selection: {
      social_support: false,
      personal_care: false,
      nursing_registration: false,
      allied_health: false,
      additional_training: false,
    },
    service_selection_error: {
      social_support: false,
      personal_care: false,
      personal_care_two: false,
      nursing_registration: false,
      allied_health: false,
      additional_training: false,
    },
    service_social_support_field: [],
    service_social_support: [],
    service_personal_care_field: [],
    service_personal_care_field_two: [],
    service_personal_care: [],
    service_personal_care_two: [],
    service_nursing_registration: {
      nursing_type: {
        disable_nursing_type_doc: true,
        nursing_type_doc: '',
        error: false,
        file_err: false,
      },
      registration_number: '',
      registration_error: false,
      work_experience: {
        disable_work_exp_doc: true,
        work_exp_doc: '',
        error: false,
        file_err: false,
      },
      checkbox: [],
    },
    nursing_checkbox: [],
    nursing_expiry_date: '',
    nursing_expiry_date_modal: false,
    nursing_expiry_date_error: false,
    service_allied_health_field: [],
    allied_health: [],
    allied_health_date_modal: [],
    allied_health_date: [],
    additional_training: [],
    service_additional_training_field: [],
    verifications: {
      first_name: '',
      last_name: '',
      know_referee: '',
      company: '',
      known_long: '',
      mobile: '',
      email: '',
      covid_training: {
        checked: false,
        covid_training_file: '',
        disable_preview: true,
        error: false,
      },
      sexual_conviction_record: {
        checked: false,
        sexual_conviction_record_file: '',
        sexual_conviction_ref: '',
        disable_preview: true,
        file_error: false,
        ref_error: false,
        date_error: false,
      },
    },
    sexual_conviction_date: '',
    sexual_conviction_date_model: false,
    bank_acc: {
      account_name: '',
      bank_name: '',
      account_number: '',
    },
    rates_availability: {
      avail_for_work: '',
      location: [],
      preferred_days: [],
      meet_greet: '',
      weekday: {
        checked: false,
        error: false,
        error_msg: '',
        rate: '',
        rate_error: false,
      },
      weekend: {
        checked: false,
        error: false,
        error_msg: '',
        rate: '',
        rate_error: false,
      },
      shift: {
        checked: false,
        error: false,
        error_msg: '',
        rate: '',
        rate_error: false,
      },
    },
    rates_availability_list_item: {
      preferred_days: [],
    },
    work_history_list: [],
    work_start_date: [],
    work_start_date_modal: [],
    work_end_date: [],
    work_end_date_modal: [],
    education_training_list: [],
    education_start_date: [],
    education_start_date_modal: [],
    education_end_date: [],
    education_end_date_modal: [],
    care_experience_collapse_list: {
      aged_care: true,
      chronic: true,
      disability: true,
      mental_health: true,
    },
    care_experience_aged_care_field: [],
    care_experience_aged_care: [],
    care_experience_chronic_field: [],
    care_experience_chronic: [],
    care_experience_disability_field: [],
    care_experience_disability: [],
    care_experience_mental_health_field: [],
    care_experience_mental_health: [],
    covid_field: [],
    covid_disable_preview: true,
    covid_file: '',
    covid_error: false,
    flu_field: [],
    flu_disable_preview: true,
    flu_file: '',
    flu_error: false,
    lgbti_field: [],
    covid: '',
    flu: '',
    lgbti: '',
    first_language: '',
    cultural_background: '',
    other_language: [],
    religion: [],
    interest: [],
    personality: '',
    non_smoker: '',
    pet_friendly: '',
    personality_list: [],
    prefer_work_with: [],
    prefer_gender: '',
    language_list: [],
    religion_list: [],
    interest_list: [],
    prefer_to_work_with_list: [],
    gender_preference_list: [],
  }),
  methods: {
    initializeStatus() {
      this.yes_no_list = [
        { 'item-text': this.$t('general.yes'), 'item-value': 'Yes' },
        { 'item-text': this.$t('general.no'), 'item-value': 'No' },
      ]

      this.worker_account_list_item.worker_status = [
        { 'item-text': this.$t('status.active'), 'item-value': 'active' },
        { 'item-text': this.$t('status.inactive'), 'item-value': 'inactive' },
      ]

      this.worker_account_list_item.worker_approval_status = [
        { 'item-text': this.$t('status.approved'), 'item-value': true },
        { 'item-text': this.$t('status.pending'), 'item-value': false },
      ]

      this.worker_account_list_item.media_to_know = [
        { 'item-text': this.$t('media_to_know.facebook'), 'item-value': 'Facebook' },
        { 'item-text': this.$t('media_to_know.instagram'), 'item-value': 'Instagram' },
        { 'item-text': this.$t('media_to_know.google_search'), 'item-value': 'Google Search' },
        { 'item-text': this.$t('media_to_know.newspaper'), 'item-value': 'Newspaper' },
        { 'item-text': this.$t('media_to_know.refer_by_friends'), 'item-value': 'Refer by Friends' },
        { 'item-text': this.$t('media_to_know.others'), 'item-value': 'Others' },
      ]

      this.worker_account_list_item.location = [
        { 'item-text': this.$t('location.central'), 'item-value': 'Central and Western' },
        { 'item-text': this.$t('location.east'), 'item-value': 'Eastern' },
        { 'item-text': this.$t('location.south'), 'item-value': 'Southern' },
        { 'item-text': this.$t('location.wc'), 'item-value': 'Wan Chai' },
        { 'item-text': this.$t('location.klc'), 'item-value': 'Kowloon City' },
        { 'item-text': this.$t('location.kt'), 'item-value': 'Kwun Tong' },
        { 'item-text': this.$t('location.ssp'), 'item-value': 'Sham Shui Po' },
        { 'item-text': this.$t('location.wts'), 'item-value': 'Wong Tai Sin' },
        { 'item-text': this.$t('location.ysm'), 'item-value': 'Yau Tsim Mong' },
        { 'item-text': this.$t('location.island'), 'item-value': 'Islands' },
        { 'item-text': this.$t('location.kc'), 'item-value': 'Kwai Tsing' },
        { 'item-text': this.$t('location.north'), 'item-value': 'North' },
        { 'item-text': this.$t('location.sk'), 'item-value': 'Sai Kung' },
        { 'item-text': this.$t('location.st'), 'item-value': 'Sha Tin' },
        { 'item-text': this.$t('location.tp'), 'item-value': 'Tai Po' },
        { 'item-text': this.$t('location.tw'), 'item-value': 'Tsuen Wan' },
        { 'item-text': this.$t('location.tm'), 'item-value': 'Tuen Mun' },
        { 'item-text': this.$t('location.yl'), 'item-value': 'Yuen Long' },
      ]

      this.worker_account_list_item.working_hour_support = [
        { 'item-text': this.$t('working_hour_support.one_to_ten'), 'item-value': '1-10 hours' },
        { 'item-text': this.$t('working_hour_support.eleven_to_twenty_five'), 'item-value': '11-25 hours' },
        { 'item-text': this.$t('working_hour_support.twenty_six_to_thirty_five'), 'item-value': '26-35 hours' },
        { 'item-text': this.$t('working_hour_support.more_than_thirty_six'), 'item-value': 'More than 36 hours' },
      ]

      this.gender_list = [
        { 'item-text': this.$t('gender.male'), 'item-value': 'Male' },
        { 'item-text': this.$t('gender.female'), 'item-value': 'Female' },
        {
          'item-text': this.$t('gender.not_to_say'),
          'item-value': 'Prefer not to say',
        },
      ]

      this.relationship_list = [
        {
          'item-text': this.$t('relationship.partner_spouse'),
          'item-value': 'Partner/ Spouse',
        },
        {
          'item-text': this.$t('relationship.immediate_family'),
          'item-value': 'Immediate Family',
        },
        {
          'item-text': this.$t('relationship.extended_family'),
          'item-value': 'Extended Family',
        },
        {
          'item-text': this.$t('relationship.legal_representative_advocate'),
          'item-value': 'Legal Representative/ Advocate',
        },
        {
          'item-text': this.$t('relationship.approved_provider_care_coordinator'),
          'item-value': 'Approved Provider/ Care Coordinator',
        },
        {
          'item-text': this.$t('relationship.friend_neighbour'),
          'item-value': 'Friend/ Neighbour',
        },
      ]

      this.worker_nursing_type = [
        {
          'item-text': this.$t('worker.registered'),
          'item-value': 'Registered',
        },
        {
          'item-text': this.$t('worker.enrolled_nurse'),
          'item-value': 'Enrolled nurse',
        },
      ]

      this.language_list = [
        {
          'item-text': this.$t('first_language_list.cantonese'),
          'item-value': 'Cantonese',
        },
        {
          'item-text': this.$t('first_language_list.english'),
          'item-value': 'English',
        },
        {
          'item-text': this.$t('first_language_list.mandarin'),
          'item-value': 'Mandarin',
        },
        {
          'item-text': this.$t('first_language_list.arabic'),
          'item-value': 'Arabic',
        },
        {
          'item-text': this.$t('first_language_list.french'),
          'item-value': 'French',
        },
        {
          'item-text': this.$t('first_language_list.german'),
          'item-value': 'German',
        },
        {
          'item-text': this.$t('first_language_list.indonesian'),
          'item-value': 'Indonesian',
        },
        {
          'item-text': this.$t('first_language_list.italian'),
          'item-value': 'Italian',
        },
        {
          'item-text': this.$t('first_language_list.japanese'),
          'item-value': 'Japanese',
        },
        {
          'item-text': this.$t('first_language_list.korean'),
          'item-value': 'Korean',
        },
        {
          'item-text': this.$t('first_language_list.spanish'),
          'item-value': 'Spanish',
        },
        {
          'item-text': this.$t('first_language_list.music'),
          'item-value': 'Music',
        },
        {
          'item-text': this.$t('first_language_list.vietnamese'),
          'item-value': 'Vietnamese',
        },
      ]

      this.religion_list = [
        {
          'item-text': this.$t('religion_list.buddhism'),
          'item-value': 'Buddhism',
        },
        {
          'item-text': this.$t('religion_list.taoism'),
          'item-value': 'Taoism',
        },
        {
          'item-text': this.$t('religion_list.confucianism'),
          'item-value': 'Confucianism',
        },
        {
          'item-text': this.$t('religion_list.catholic'),
          'item-value': 'Catholic',
        },
        {
          'item-text': this.$t('religion_list.christian'),
          'item-value': 'Christian',
        },
        {
          'item-text': this.$t('religion_list.muslim'),
          'item-value': 'Muslim',
        },
        {
          'item-text': this.$t('religion_list.hindu'),
          'item-value': 'Hindu',
        },
        {
          'item-text': this.$t('religion_list.sikh'),
          'item-value': 'Sikh',
        },
        {
          'item-text': this.$t('religion_list.other'),
          'item-value': 'Other religion',
        },
        {
          'item-text': this.$t('religion_list.no_religion'),
          'item-value': 'No religion',
        },
      ]

      this.interest_list = [
        {
          'item-text': this.$t('interest_list.cooking'),
          'item-value': 'Cooking',
        },
        {
          'item-text': this.$t('interest_list.pets'),
          'item-value': 'Pets',
        },
        {
          'item-text': this.$t('interest_list.cultural_festivities'),
          'item-value': 'Cultural Festivities',
        },
        {
          'item-text': this.$t('interest_list.photography'),
          'item-value': 'Photography',
        },
        {
          'item-text': this.$t('interest_list.art'),
          'item-value': 'Art',
        },
        {
          'item-text': this.$t('interest_list.reading'),
          'item-value': 'Reading',
        },
        {
          'item-text': this.$t('interest_list.indoor_games'),
          'item-value': 'Indoor Games',
        },
        {
          'item-text': this.$t('interest_list.puzzles'),
          'item-value': 'Puzzles',
        },
        {
          'item-text': this.$t('interest_list.sports'),
          'item-value': 'Sports',
        },
        {
          'item-text': this.$t('interest_list.movies'),
          'item-value': 'Movies',
        },
        {
          'item-text': this.$t('interest_list.travel'),
          'item-value': 'Travel',
        },
        {
          'item-text': this.$t('interest_list.music'),
          'item-value': 'Music',
        },
        {
          'item-text': this.$t('interest_list.other'),
          'item-value': 'Other',
        },
      ]

      this.rates_availability_list_item.preferred_days = [
        {
          'item-text': this.$t('days.mon'),
          'item-value': 'Mon',
        },
        {
          'item-text': this.$t('days.tue'),
          'item-value': 'Tue',
        },
        {
          'item-text': this.$t('days.wed'),
          'item-value': 'Wed',
        },
        {
          'item-text': this.$t('days.thu'),
          'item-value': 'Thu',
        },
        {
          'item-text': this.$t('days.fri'),
          'item-value': 'Fri',
        },
        {
          'item-text': this.$t('days.sat'),
          'item-value': 'Sat',
        },
        {
          'item-text': this.$t('days.sun'),
          'item-value': 'Sun',
        },
      ]

      this.prefer_to_work_with_list = [
        {
          'item-text': this.$t('prefer_to_work_with.non_smoker'),
          'item-value': 'Non-smoker',
        },
        {
          'item-text': this.$t('prefer_to_work_with.no_pet'),
          'item-value': 'No Pets',
        },
        {
          'item-text': this.$t('prefer_to_work_with.no_preference'),
          'item-value': 'No Preferences',
        },
      ]

      this.personality_list = [
        {
          'item-text': this.$t('worker.calm'),
          'item-value': 'Calm and Composed',
        },
        {
          'item-text': this.$t('worker.outgoing'),
          'item-value': 'Outgoing and sociable',
        },
      ]

      this.gender_preference_list = [
        {
          'item-text': this.$t('gender_preference.female'),
          'item-value': 'Female Only',
        },
        {
          'item-text': this.$t('gender_preference.male'),
          'item-value': 'Male only',
        },
        {
          'item-text': this.$t('gender_preference.no_preference'),
          'item-value': 'No Preferences',
        },
      ]
    },
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set('get_user_by_id', JSON.stringify({ id: parseInt(this.$route.params.id, 10) }))
        let get_user_data = await getData(params)

        if (get_user_data.user_type == 'customer') {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.worker')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'Worker',
          })
          return
        }

        this.$func.log('-----Get User Data-----')
        this.$func.log(get_user_data)

        params = new URLSearchParams()
        params.set('get_worker_average', JSON.stringify({ worker_id: parseInt(this.$route.params.id, 10) }))
        let get_rating_data = await getData(params)
        this.$func.log('-----Get Rating Data-----')
        this.$func.log(get_rating_data)

        // Worker Account
        this.worker_account.id = get_user_data.id
        this.worker_account.email = get_user_data.email
        this.worker_account.create_date = globalFunc.getDateStr(get_user_data.create_date)
        this.worker_account.location = get_user_data.location
        this.worker_account.rating = get_rating_data
        this.worker_account.working_hour_support = get_user_data.working_hour
        this.worker_account.media_to_know = get_user_data.hear_from
        this.worker_account.status = get_user_data.status
        this.worker_account.user_approved = get_user_data.user_approved

        if (get_user_data.request_approve) {
          this.worker_account.disable_user_approved = false
        } else {
          this.worker_account.disable_user_approved = true
        }

        // Worker Profile
        this.worker_profile.profile_pic = get_user_data.profile_pic
        if (globalFunc.DataValid(get_user_data.profile_pic)) {
          this.disable_profile_photo = false
        }
        this.worker_profile.first_name = get_user_data.first_name
        this.worker_profile.last_name = get_user_data.last_name
        this.worker_profile.nickname = get_user_data.nick_name
        this.worker_profile.gender = get_user_data.gender
        this.worker_profile.mobile = get_user_data.mobile
        this.worker_profile.residential_addr = get_user_data.addr
        this.worker_profile.postal_addr = get_user_data.post_addr
        this.worker_profile.bio = get_user_data.bio
        this.date_of_birth = globalFunc.getDateStr(get_user_data.birthday)

        // Emergency Contacts
        for (let i = 0; i < get_user_data.emergency.length; i++) {
          let emergency = {}
          Object.assign(emergency, get_user_data.emergency[i])
          delete emergency.other_info
          this.addEmergencyContact(null, emergency)
        }

        // Social Support
        this.service_selection.social_support = get_user_data.ssda
        let ssda_items = get_user_data.ssda_items

        if (ssda_items.length > 0) {
          this.service_social_support = JSON.parse(JSON.stringify(ssda_items))
          this.service_collapse_list.social_support = false

          if (ssda_items.includes('Provide Transport')) {
            let index = this.service_social_support_field.findIndex(obj => {
              return obj.value == 'Provide Transport'
            })

            if (index != -1) {
              this.service_social_support_field[index].show_upload_field = true
              this.service_social_support_field[index].disable_preview = false
              this.service_social_support_field[index].file =
                get_user_data[this.getFileName('ssda', 'Provide Transport')]
            }
          }

          // For Work Experience
          let index = this.service_social_support_field.findIndex(obj => {
            return obj.value == 'Work Experience'
          })

          if (index != -1) {
            this.service_social_support_field[index].show_upload_field = true
            this.service_social_support_field[index].disable_preview = false
            this.service_social_support_field[index].file = get_user_data[this.getFileName('ssda', 'Work Experience')]
          }
        }

        // Personal Care
        this.service_selection.personal_care = get_user_data.personal_care
        let personal_care = get_user_data.personal_care_items

        if (personal_care.length > 0) {
          this.service_collapse_list.personal_care = false
          this.service_personal_care = JSON.parse(JSON.stringify(personal_care))

          for (let i = 0; i < this.service_personal_care_field.length; i++) {
            if (personal_care.includes(this.service_personal_care_field[i].value)) {
              if (this.service_personal_care_field[i].upload_required) {
                this.service_personal_care_field[i].show_upload_field = true
                let file = this.getFileName('personal_care', this.service_personal_care_field[i].value)
                if (globalFunc.DataValid(file)) {
                  this.service_personal_care_field[i].show_upload_field = true
                  this.service_personal_care_field[i].disable_preview = false
                  this.service_personal_care_field[i].file = get_user_data[file]
                }
              }
            }
          }
        }

        // Personal Care 2 = personal_service
        let personal_service = get_user_data.personal_service
        if (personal_service.length > 0) {
          this.service_personal_care_two = JSON.parse(JSON.stringify(personal_service))
          for (let i = 0; i < this.service_personal_care_field_two.length; i++) {
            if (personal_service.includes(this.service_personal_care_field_two[i].value)) {
              if (this.service_personal_care_field_two[i].upload_required) {
                this.service_personal_care_field_two[i].show_upload_field = true
                let file = this.getFileName('personal_care', this.service_personal_care_field_two[i].value)
                if (globalFunc.DataValid(file)) {
                  this.service_personal_care_field_two[i].show_upload_field = true
                  this.service_personal_care_field_two[i].disable_preview = false
                  this.service_personal_care_field_two[i].file = get_user_data[file]
                }
              }
            }
          }
        }

        // Nursing Registration
        this.service_selection.nursing_registration = get_user_data.nursing
        if (globalFunc.DataValid(get_user_data.nursing_type)) {
          this.service_collapse_list.nursing_registration = false
          this.service_nursing_registration.nursing_type.type = get_user_data.nursing_type

          if (globalFunc.DataValid(get_user_data.nursing_type_file)) {
            this.service_nursing_registration.nursing_type.nursing_type_doc = get_user_data.nursing_type_file
            this.service_nursing_registration.nursing_type.disable_nursing_type_doc = false
          }

          this.service_nursing_registration.registration_number = get_user_data.nursing_reg_no
          this.nursing_expiry_date = get_user_data.nursing_expiry_date

          if (globalFunc.DataValid(get_user_data.nursing_exp_file)) {
            this.service_nursing_registration.work_experience.work_exp_doc = get_user_data.nursing_exp_file
            this.service_nursing_registration.work_experience.disable_work_exp_doc = false
          }
        }

        let nursing_care = get_user_data.nursing_care_item
        if (nursing_care.length > 0) {
          this.nursing_checkbox = JSON.parse(JSON.stringify(nursing_care))
          for (let i = 0; i < this.service_nursing_registration.checkbox.length; i++) {
            if (nursing_care.includes(this.service_nursing_registration.checkbox[i].value)) {
              if (this.service_nursing_registration.checkbox[i].upload_required) {
                this.service_nursing_registration.checkbox[i].show_upload_field = true
                let file = this.getFileName('nursing', this.service_nursing_registration.checkbox[i].value)
                if (globalFunc.DataValid(file)) {
                  this.service_nursing_registration.checkbox[i].show_upload_field = true
                  this.service_nursing_registration.checkbox[i].disable_preview = false
                  this.service_nursing_registration.checkbox[i].file = get_user_data[file]
                }
              }
            }
          }
        }

        // Allied Health Service
        this.service_selection.allied_health = get_user_data.allied_health
        let allied_prefix = ['allied_ot', 'allied_p', 'allied_ps', 'allied_st', 'allied_sw']

        for (let i = 0; i < allied_prefix.length; i++) {
          if (get_user_data[`${allied_prefix[i]}_health`]) {
            this.allied_health.push(this.service_allied_health_field[i].value)
            this.service_allied_health_field[i].show_upload_field = true
          }

          this.service_allied_health_field[i]['registration_number'] = get_user_data[`${allied_prefix[i]}_reg_no`]
          this.service_allied_health_field[i]['expiry_date'] = get_user_data[`${allied_prefix[i]}_expiry_date`]
          this.allied_health_date[i] = get_user_data[`${allied_prefix[i]}_expiry_date`]

          if (globalFunc.DataValid(get_user_data[`${allied_prefix[i]}_type_file`])) {
            this.service_allied_health_field[i].show_upload_field = true
            this.service_allied_health_field[i].disable_preview = false
            this.service_allied_health_field[i].file = get_user_data[`${allied_prefix[i]}_type_file`]
          }

          if (globalFunc.DataValid(get_user_data[`${allied_prefix[i]}_exp_file`])) {
            this.service_allied_health_field[i].work_experience.disable_preview = false
            this.service_allied_health_field[i].work_experience.file = get_user_data[`${allied_prefix[i]}_exp_file`]
          }
        }

        if (this.allied_health.length > 0) {
          this.service_collapse_list.allied_health = false
        }

        // Additional Training
        this.service_selection.additional_training = get_user_data.additional_training
        let additional_training_prefix = ['first_aid', 'manual_handing_cert', 'driving_licence']
        for (let i = 0; i < additional_training_prefix.length; i++) {
          if (get_user_data[additional_training_prefix[i]]) {
            this.additional_training.push(this.service_additional_training_field[i].value)
            this.service_additional_training_field[i].show_upload_field = true
          }

          if (globalFunc.DataValid(get_user_data[`${additional_training_prefix[i]}_file`])) {
            this.service_additional_training_field[i].show_upload_field = true
            this.service_additional_training_field[i].disable_preview = false
            this.service_additional_training_field[i].file = get_user_data[`${additional_training_prefix[i]}_file`]
          }
        }

        if (this.additional_training.length > 0) {
          this.service_collapse_list.additional_training = false
        }

        // Verifications
        this.verifications.first_name = get_user_data.verif_first_name
        this.verifications.last_name = get_user_data.verif_last_name
        this.verifications.know_referee = get_user_data.verif_know_referee
        this.verifications.company = get_user_data.verif_company
        this.verifications.known_long = get_user_data.verif_known_long
        this.verifications.mobile = get_user_data.verif_mobile
        this.verifications.email = get_user_data.verif_email
        this.verifications.covid_training.checked = get_user_data.covid_training
        this.verifications.covid_training.covid_training_file = get_user_data.covid_training_file
        if (globalFunc.DataValid(get_user_data.covid_training_file)) {
          this.verifications.covid_training.disable_preview = false
        }

        this.verifications.sexual_conviction_record.checked = get_user_data.sexual_conviction_record
        this.verifications.sexual_conviction_record.sexual_conviction_record_file =
          get_user_data.sexual_conviction_record_file
        this.verifications.sexual_conviction_record.sexual_conviction_ref = get_user_data.sexual_conviction_ref
        this.sexual_conviction_date = get_user_data.sexual_conviction_date

        if (globalFunc.DataValid(get_user_data.sexual_conviction_record_file)) {
          this.verifications.sexual_conviction_record.disable_preview = false
        }

        // Bank Account
        this.bank_acc.account_name = get_user_data.bank_ac_name
        this.bank_acc.bank_name = get_user_data.bank_name
        this.bank_acc.account_number = get_user_data.bank_ac_no

        // Rates and availability
        this.rates_availability.avail_for_work = get_user_data.ava_for_work ? 'Yes' : 'No'
        this.rates_availability.location = get_user_data.location_for_work
        this.rates_availability.preferred_days = get_user_data.preferred_days
        this.rates_availability.meet_greet = get_user_data.meet_greet ? 'Yes' : 'No'
        this.rates_availability.weekday.checked = get_user_data.weekday_rate
        this.rates_availability.weekday.rate = get_user_data.weekday_rate_price
        this.rates_availability.weekend.checked = get_user_data.weekend_rate
        this.rates_availability.weekend.rate = get_user_data.weekend_rate_price
        this.rates_availability.shift.checked = get_user_data.shift_rate
        this.rates_availability.shift.rate = get_user_data.shift_rate_price

        // Work History
        let work_history = get_user_data.work_history

        for (let i = 0; i < work_history.length; i++) {
          let temp = {
            id: work_history[i].id,
            current_working: work_history[i].curr,
            job_title: work_history[i].title,
            company: work_history[i].company,
          }

          if (globalFunc.DataValid(work_history[i].start_date)) {
            let start_month = work_history[i].start_date.split('-')
            if (start_month[1].length == 1) {
              temp['start_date'] = start_month[0] + '-0' + start_month[1]
            } else {
              temp['start_date'] = work_history[i].start_date
            }
          } else {
            temp['start_date'] = ''
          }

          if (globalFunc.DataValid(work_history[i].end_date)) {
            let end_month = work_history[i].end_date.split('-')
            if (end_month[1].length == 1) {
              temp['end_date'] = end_month[0] + '-0' + end_month[1]
            } else {
              temp['end_date'] = work_history[i].end_date
            }
          } else {
            temp['end_date'] = ''
          }

          this.addWorkHistory(null, temp)
        }

        // Education/training
        let education = get_user_data.education

        for (let i = 0; i < education.length; i++) {
          let temp = {
            id: education[i].id,
            current_working: education[i].curr,
            institution: education[i].institution,
            degree_course: education[i].degree,
          }

          if (globalFunc.DataValid(education[i].start_date)) {
            let start_month = education[i].start_date.split('-')
            if (start_month[1].length == 1) {
              temp['start_date'] = start_month[0] + '-0' + start_month[1]
            } else {
              temp['start_date'] = education[i].start_date
            }
          } else {
            temp['start_date'] = ''
          }

          if (globalFunc.DataValid(education[i].end_date)) {
            let end_month = education[i].end_date.split('-')
            if (end_month[1].length == 1) {
              temp['end_date'] = end_month[0] + '-0' + end_month[1]
            } else {
              temp['end_date'] = education[i].end_date
            }
          } else {
            temp['end_date'] = ''
          }

          this.addEducationTraining(null, temp)
        }

        // Care Experience
        this.care_experience_aged_care = get_user_data.aged_care
        if (this.care_experience_aged_care.length > 0) {
          this.care_experience_collapse_list.aged_care = false
        }
        this.care_experience_chronic = get_user_data.chronic_medical
        if (this.care_experience_chronic.length > 0) {
          this.care_experience_collapse_list.chronic = false
        }
        this.care_experience_disability = get_user_data.disability
        if (this.care_experience_disability.length > 0) {
          this.care_experience_collapse_list.disability = false
        }
        this.care_experience_mental_health = get_user_data.mental_health
        if (this.care_experience_mental_health.length > 0) {
          this.care_experience_collapse_list.mental_health = false
        }

        // Badges
        this.covid = get_user_data.covid_vaccine
        if (globalFunc.DataValid(get_user_data.covid_vaccine_file)) {
          this.covid_disable_preview = false
          this.covid_file = get_user_data.covid_vaccine_file
        }
        this.flu = get_user_data.flu_vaccine
        if (globalFunc.DataValid(get_user_data.flu_vaccine_file)) {
          this.flu_disable_preview = false
          this.flu_file = get_user_data.flu_vaccine_file
        }
        this.lgbti = get_user_data.lgbti

        this.first_language = get_user_data.first_language
        this.cultural_background = get_user_data.cultural
        this.other_language = get_user_data.other_languages
        this.religion = get_user_data.religion
        this.interest = get_user_data.hobbies
        this.personality = get_user_data.personality_at_work
        this.non_smoker = get_user_data.non_smoker ? 'Yes' : 'No'
        this.pet_friendly = get_user_data.pet_friendly ? 'Yes' : 'No'
        this.prefer_work_with = get_user_data.preference_work_with
        this.prefer_gender = get_user_data.preference_gender
      } catch (error) {
        this.$func.log(error)
        if (Array.isArray(error)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.worker')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'Worker',
          })
        }
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    getFileName(source, value) {
      let file_name = ''

      if (source == 'ssda') {
        switch (value) {
          case 'Provide Transport':
            file_name = 'provide_transport_file'
            break
          case 'Work Experience':
            file_name = 'work_exp_file'
            break
        }
      } else if (source == 'personal_care') {
        switch (value) {
          case 'Certificate of Combined Health Worker Training Course':
            file_name = 'cchwtc_file'
            break
          case 'Certificate of Training Course for Health Worker in Residential Care Homes for the Elderly':
            file_name = 'ctchwrche_file'
            break
          case 'Certificate of Training Course for Health Worker in Residential Care Homes for Persons with Disabilities':
            file_name = 'ctchwrchpd_file'
            break
          case 'Practising certificates for Registered':
            file_name = 'pcr_file'
            break
          case 'Enrolled nurse issued by the Nursing Council':
            file_name = 'ennc_file'
            break
          case 'Working Toward Degree Nursing':
            file_name = 'wtdn_file'
            break
          case 'Other relevant qualification':
            file_name = 'other_relevant_file'
            break
          case '1 or more years of experience':
            file_name = 'one_or_more_exp_file'
            break
          case 'Assistance with Eating':
            file_name = 'assist_eaing_file'
            break
          case 'Assist with Medication':
            file_name = 'medication_assist_file'
            break
          case 'Manual Transfer and Mobility':
            file_name = 'manual_transfer_file'
            break
        }
      } else if (source == 'nursing') {
        switch (value) {
          case 'Dementia support':
            file_name = 'dementia_support_file'
            break
          case 'Palliative care':
            file_name = 'palliative_care_file'
            break
        }
      }

      return file_name
    },
    addEmergencyContact(e, obj = null) {
      if (obj != null) {
        let temp_data = {}
        Object.assign(temp_data, obj)
        temp_data['error'] = { name: false, mobile: '', email: '', relationship: false }
        this.emergency_contact_list.push(temp_data)
      } else {
        this.emergency_contact_list.push({
          name: '',
          mobile: '',
          email: '',
          relationship: '',
          error: {
            name: false,
            mobile: '',
            email: '',
            relationship: false,
          },
        })
      }
    },
    removeEmergencyContact(index) {
      this.emergency_contact_list.splice(index, 1)
    },
    addWorkHistory(e, obj = null) {
      if (obj != null) {
        let temp_data = {}
        Object.assign(temp_data, obj)
        temp_data['error'] = { job_title: false, company: false, start_date: '', end_date: '' }
        this.work_history_list.push(temp_data)
        this.work_start_date.push(temp_data.start_date)
        this.work_end_date.push(temp_data.end_date)
      } else {
        this.work_history_list.push({
          current_working: false,
          job_title: '',
          company: '',
          start_date: '',
          end_date: '',
          error: {
            job_title: false,
            company: false,
            start_date: '',
            end_date: '',
          },
        })

        this.work_start_date.push('')
        this.work_end_date.push('')
      }

      this.work_start_date_modal.push(false)
      this.work_end_date_modal.push(false)
    },
    removeWorkHistory(index) {
      this.work_history_list.splice(index, 1)
      this.work_start_date.splice(index, 1)
      this.work_end_date.splice(index, 1)
      this.work_start_date_modal.splice(index, 1)
      this.work_end_date_modal.splice(index, 1)
    },
    addEducationTraining(e, obj = null) {
      if (obj != null) {
        let temp_data = {}
        Object.assign(temp_data, obj)
        temp_data['error'] = { institution: false, degree_course: false, start_date: '', end_date: '' }
        this.education_training_list.push(temp_data)
        this.education_start_date.push(temp_data.start_date)
        this.education_end_date.push(temp_data.end_date)
      } else {
        this.education_training_list.push({
          current_working: false,
          institution: '',
          degree_course: '',
          start_date: '',
          end_date: '',
          error: {
            institution: false,
            degree_course: false,
            start_date: '',
            end_date: '',
          },
        })

        this.education_start_date.push('')
        this.education_end_date.push('')
      }

      this.education_start_date_modal.push(false)
      this.education_end_date_modal.push(false)
    },
    removeEducationTraining(index) {
      this.education_training_list.splice(index, 1)
      this.education_start_date.splice(index, 1)
      this.education_end_date.splice(index, 1)
      this.education_start_date_modal.splice(index, 1)
      this.education_end_date_modal.splice(index, 1)
    },
    setFieldData(text, value, upload_required = false, required = false) {
      let obj = {
        text: text,
        value: value,
        upload_required: upload_required,
        show_upload_field: false,
        disable_preview: true,
        file: '',
        required: required,
        error: false,
      }

      return obj
    },
    initializeServiceField() {
      this.service_social_support_field = [
        this.setFieldData(
          'service_social_support_field.activities_outings_community_access',
          'Activities, Outings & Community Access',
        ),
        this.setFieldData('service_social_support_field.home_maintenance', 'Home Maintenance'),
        this.setFieldData('service_social_support_field.light_housework', 'Light Housework'),
        this.setFieldData('service_social_support_field.meal_delivery', 'Meal Delivery'),
        this.setFieldData('service_social_support_field.meal_preparation', 'Meal Preparation'),
        this.setFieldData('service_social_support_field.personal_assistant', 'Personal Assistant (Admin)'),
        this.setFieldData('service_social_support_field.provide_transport', 'Provide Transport', true),
        this.setFieldData('service_social_support_field.shopping', 'Shopping'),
        this.setFieldData('service_social_support_field.sports_exercise', 'Sports & Exercise'),
        this.setFieldData('service_social_support_field.work_experience', 'Work Experience', true),
      ]

      this.service_personal_care_field = [
        this.setFieldData(
          'service_personal_care_field.combined_health_worker_training_course',
          'Certificate of Combined Health Worker Training Course',
          true,
        ),
        this.setFieldData(
          'service_personal_care_field.residential_care_homes_for_elderly',
          'Certificate of Training Course for Health Worker in Residential Care Homes for the Elderly',
          true,
        ),
        this.setFieldData(
          'service_personal_care_field.residential_care_homes_for_disabilities',
          'Certificate of Training Course for Health Worker in Residential Care Homes for Persons with Disabilities',
          true,
        ),
        this.setFieldData(
          'service_personal_care_field.practising_certificates_for_registered',
          'Practising certificates for Registered',
          true,
        ),
        this.setFieldData(
          'service_personal_care_field.enrolled_nurse',
          'Enrolled nurse issued by the Nursing Council',
          true,
        ),
        this.setFieldData(
          'service_personal_care_field.working_toward_degree_nursing',
          'Working Toward Degree Nursing',
          true,
        ),
        this.setFieldData('service_personal_care_field.other_qualification', 'Other relevant qualification', true),
        this.setFieldData('service_personal_care_field.experience', '1 or more years of experience', true, true),
      ]

      this.service_personal_care_field_two = [
        this.setFieldData('service_personal_care_field.assistance_with_eating', 'Assistance with Eating', true),
        this.setFieldData('service_personal_care_field.assist_with_medication', 'Assist with Medication', true),
        this.setFieldData('service_personal_care_field.care_assessment', 'Care Assessment, Planning, Coordination'),
        this.setFieldData('service_personal_care_field.exercise_assistance', 'Exercise Assistance'),
        this.setFieldData('service_personal_care_field.hoist_transfer', 'Hoist & Transfer', true),
        this.setFieldData('service_personal_care_field.light_massage', 'Light Massage'),
        this.setFieldData(
          'service_personal_care_field.manual_transfer_and_mobility',
          'Manual Transfer and Mobility',
          true,
        ),
        this.setFieldData('service_personal_care_field.palliative_care', 'Palliative Care'),
        this.setFieldData('service_personal_care_field.showering_dressing_grooming', 'Showering, Dressing, Grooming'),
        this.setFieldData('service_personal_care_field.toileting', 'Toileting'),
      ]

      this.service_nursing_registration.checkbox = [
        this.setFieldData('nursing_registration_field.conduction_health_assessments', 'Conducting health assessments'),
        this.setFieldData('nursing_registration_field.medication_management', 'Medication management'),
        this.setFieldData('nursing_registration_field.chronic_disease_monitoring', 'Chronic disease monitoring'),
        this.setFieldData('nursing_registration_field.pain_management', 'Pain management'),
        this.setFieldData('nursing_registration_field.oxygen_therapy', 'Oxygen therapy'),
        this.setFieldData('nursing_registration_field.post_operative_recovery', 'Post-operative recovery'),
        this.setFieldData('nursing_registration_field.wound_care', 'Wound care'),
        this.setFieldData('nursing_registration_field.catheter_care', 'Catheter care'),
        this.setFieldData('nursing_registration_field.dementia_support', 'Dementia support', true),
        this.setFieldData('nursing_registration_field.continence_management', 'Continence management'),
        this.setFieldData('nursing_registration_field.palliative_care', 'Palliative care', true),
      ]

      this.service_allied_health_field = [
        this.setFieldData('allied_health_field.occupational_therapy', 'Occupational Therapy', true),
        this.setFieldData('allied_health_field.physiotherapy', 'Physiotherapy', true),
        this.setFieldData('allied_health_field.psychological_services', 'Psychological Services', true),
        this.setFieldData('allied_health_field.speech_therapy', 'Speech Therapy', true),
        this.setFieldData('allied_health_field.social_work', 'Social Work', true),
      ]

      for (let i = 0; i < this.service_allied_health_field.length; i++) {
        this.service_allied_health_field[i]['registration_number'] = ''
        this.service_allied_health_field[i]['registration_number_error'] = false
        this.service_allied_health_field[i]['expiry_date'] = ''
        this.service_allied_health_field[i]['expiry_date_error'] = false
        let wrok_experience = {
          disable_preview: true,
          file: '',
          error: false,
        }
        this.service_allied_health_field[i]['work_experience'] = wrok_experience
        this.allied_health_date_modal.push(false)
        this.allied_health_date.push('')
      }

      this.service_additional_training_field = [
        this.setFieldData('additional_training_field.first_aid', 'First Aid', true),
        this.setFieldData('additional_training_field.manual_handing_certificate', 'Manual Handling Certificate', true),
        this.setFieldData('additional_training_field.valid_driver_licence', 'Valid Driver’s Licence', true),
      ]
    },
    initializeCareExperienceField() {
      this.care_experience_aged_care_field = [
        this.setFieldData('aged_care_field.dementia', 'Dementia'),
        this.setFieldData('aged_care_field.parkinson_disease', 'Parkinson’s Disease'),
      ]

      this.care_experience_chronic_field = [
        this.setFieldData('chronic_field.arthritis', 'Arthritis'),
        this.setFieldData('chronic_field.asthma', 'Asthma'),
        this.setFieldData('chronic_field.cardiovascular_disease', 'Cardiovascular Disease'),
        this.setFieldData('chronic_field.copd', 'COPD or Respiratory Illness'),
        this.setFieldData('chronic_field.diabetes', 'Diabetes'),
      ]

      this.care_experience_disability_field = [
        this.setFieldData('disability_field.acquired_brain_injury', 'Acquired Brain Injury'),
        this.setFieldData('disability_field.autism', 'Autism'),
        this.setFieldData('disability_field.cerebral_palsy', 'Cerebral Palsy'),
        this.setFieldData('disability_field.cystic_fibrosis', 'Cystic Fibrosis'),
        this.setFieldData('disability_field.down_syndrome', 'Down Syndrome'),
        this.setFieldData('disability_field.epilepsy', 'Epilepsy'),
        this.setFieldData('disability_field.hearing_impairment', 'Hearing Impairment'),
        this.setFieldData('disability_field.intellectual_disabilities', 'Intellectual Disabilities'),
        this.setFieldData('disability_field.motor_neuron_disease', 'Motor Neuron Disease'),
        this.setFieldData('disability_field.spina_bifida', 'Spina Bifida'),
        this.setFieldData('disability_field.spinal_cord_injury', 'Spinal Cord Injury'),
        this.setFieldData('disability_field.vision_impairement', 'Vision Impairement'),
      ]

      this.care_experience_mental_health_field = [
        this.setFieldData('mental_health_field.anxiety', 'Anxiety'),
        this.setFieldData('mental_health_field.bipolar_disorder', 'Bipolar Disorder'),
        this.setFieldData('mental_health_field.depression', 'Depression'),
        this.setFieldData('mental_health_field.eating_disorders', 'Eating Disorders'),
        this.setFieldData('mental_health_field.hoarding', 'Hoarding'),
        this.setFieldData('mental_health_field.ocd', 'Obessive-Compulsive Disorder (OCD)'),
        this.setFieldData('mental_health_field.ptsd', 'Post-traumatic Stress Disorder (PTSD)'),
        this.setFieldData('mental_health_field.schizophrenia', 'Schizophrenia'),
        this.setFieldData('mental_health_field.substance_abuse_addiction', 'Substance Abuse & Addiction'),
      ]
    },
    initializeBadgesField() {
      this.covid_field = [
        this.setFieldData('badges_field.yes_one_dose', 'Yes, at least one dose', true),
        this.setFieldData('badges_field.yes_fully_vaccinated', 'Yes, fully vaccinated', true),
        this.setFieldData('general.no', 'No'),
      ]
      this.flu_field = [this.setFieldData('general.yes', true, true), this.setFieldData('general.no', false)]
      this.lgbti_field = [this.setFieldData('general.yes', true), this.setFieldData('general.no', false)]
    },
    checkShowFileInput(type, index = -1) {
      if (type == 'social_support') {
        if (this.service_social_support_field[index].upload_required) {
          if (this.service_social_support.includes(this.service_social_support_field[index].value)) {
            this.service_social_support_field[index].show_upload_field = true
          } else {
            this.service_social_support_field[index].show_upload_field = false
          }
        }
      } else if (type == 'personal_care') {
        if (this.service_personal_care_field[index].upload_required) {
          if (this.service_personal_care.includes(this.service_personal_care_field[index].value)) {
            this.service_personal_care_field[index].show_upload_field = true
          } else {
            this.service_personal_care_field[index].show_upload_field = false
          }
        }
      } else if (type == 'personal_care_two') {
        if (this.service_personal_care_field_two[index].upload_required) {
          if (this.service_personal_care_two.includes(this.service_personal_care_field_two[index].value)) {
            this.service_personal_care_field_two[index].show_upload_field = true
          } else {
            this.service_personal_care_field_two[index].show_upload_field = false
          }
        }
      } else if (type == 'nursing') {
        if (this.service_nursing_registration.checkbox[index].upload_required) {
          if (this.nursing_checkbox.includes(this.service_nursing_registration.checkbox[index].value)) {
            this.service_nursing_registration.checkbox[index].show_upload_field = true
          } else {
            this.service_nursing_registration.checkbox[index].show_upload_field = false
          }
        }
      } else if (type == 'allied') {
        if (this.service_allied_health_field[index].upload_required) {
          if (this.allied_health.includes(this.service_allied_health_field[index].value)) {
            this.service_allied_health_field[index].show_upload_field = true
          } else {
            this.service_allied_health_field[index].show_upload_field = false
          }
        }
      } else if (type == 'additional_training') {
        if (this.service_additional_training_field[index].upload_required) {
          if (this.additional_training.includes(this.service_additional_training_field[index].value)) {
            this.service_additional_training_field[index].show_upload_field = true
          } else {
            this.service_additional_training_field[index].show_upload_field = false
          }
        }
      }
    },
    selectFile(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file, type, index = -1) {
      if (globalFunc.DataValid(file)) {
        if (type == 'profile_photo') {
          if (file.type.indexOf('image/') == -1) {
            this.submitLoading = false

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: `${this.$t('error.force_upload')} ${this.$t('error.image_file')}`,
              type: 'error',
              refresh: false,
              redirect: '',
            })
            return
          }
        } else {
          if (file.type.indexOf('image/') == -1 && file.type != 'application/pdf') {
            this.submitLoading = false

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: `${this.$t('error.force_upload')} ${this.$t('error.image_pdf_file')}`,
              type: 'error',
              refresh: false,
              redirect: '',
            })
            return
          }
        }

        this.submitLoading = true
        try {
          let base64Path = await globalFunc.firstFileToBase64(file)
          let file_type = file.name.substring(file.name.lastIndexOf('.') + 1)
          let params = new URLSearchParams()
          params.set(
            'upload_file',
            JSON.stringify({
              upload_data: base64Path,
              upload_file_type: file_type,
            }),
          )

          let upload_file = await postData(params)
          this.$func.log('--- upload success ---')
          this.$func.log(upload_file)

          if (type == 'profile_photo') {
            this.worker_profile.profile_pic = upload_file
            this.worker_profile.disable_preview = false
          } else if (type == 'social_support') {
            this.service_social_support_field[index].file = upload_file
            this.service_social_support_field[index].disable_preview = false
            this.service_social_support_field[index].error = false
          } else if (type == 'personal_care') {
            this.service_personal_care_field[index].file = upload_file
            this.service_personal_care_field[index].disable_preview = false
            this.service_personal_care_field[index].error = false
          } else if (type == 'personal_care_two') {
            this.service_personal_care_field_two[index].file = upload_file
            this.service_personal_care_field_two[index].disable_preview = false
            this.service_personal_care_field_two[index].error = false
          } else if (type == 'nursing_type') {
            this.service_nursing_registration.nursing_type.nursing_type_doc = upload_file
            this.service_nursing_registration.nursing_type.disable_nursing_type_doc = false
            this.service_nursing_registration.nursing_type.file_err = false
          } else if (type == 'nursing_work_experience') {
            this.service_nursing_registration.work_experience.work_exp_doc = upload_file
            this.service_nursing_registration.work_experience.disable_work_exp_doc = false
            this.service_nursing_registration.work_experience.file_err = false
          } else if (type == 'nursing') {
            this.service_nursing_registration.checkbox[index].file = upload_file
            this.service_nursing_registration.checkbox[index].disable_preview = false
            this.service_nursing_registration.checkbox[index].error = false
          } else if (type == 'allied') {
            this.service_allied_health_field[index].file = upload_file
            this.service_allied_health_field[index].disable_preview = false
            this.service_allied_health_field[index].error = false
          } else if (type == 'allied_work_exp') {
            this.service_allied_health_field[index].work_experience.file = upload_file
            this.service_allied_health_field[index].work_experience.disable_preview = false
            this.service_allied_health_field[index].work_experience.error = false
          } else if (type == 'additional_training') {
            this.service_additional_training_field[index].file = upload_file
            this.service_additional_training_field[index].disable_preview = false
            this.service_additional_training_field[index].error = false
          } else if (type == 'badges_covid') {
            this.covid_file = upload_file
            this.covid_disable_preview = false
            this.covid_error = false
          } else if (type == 'badges_flu') {
            this.flu_file = upload_file
            this.flu_disable_preview = false
            this.flu_error = false
          } else if (type == 'covid_19_training') {
            this.verifications.covid_training.covid_training_file = upload_file
            this.verifications.covid_training.disable_preview = false
            this.verifications.covid_training.error = false
          } else if (type == 'sexual_conviction_record') {
            this.verifications.sexual_conviction_record.sexual_conviction_record_file = upload_file
            this.verifications.sexual_conviction_record.disable_preview = false
            this.verifications.sexual_conviction_record.file_error = false
          }

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_success'),
            type: 'success',
            refresh: false,
            redirect: '',
          })
        } catch (error) {
          this.$func.log(error)
          this.$func.log('--- upload fail ---')
          this.$func.log(data)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } else {
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: `${this.$t('error.force_upload')} ${this.$t('error.file')}`,
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    previewFile(fileName) {
      if (globalFunc.DataValid(fileName)) {
        let link = MEDIA_PATH + fileName
        window.open(link, '_blank')
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.file_not_found'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    removeFile() {
      this.worker_profile.profile_pic = ''
      this.disable_profile_photo = true
    },
    customValidate() {
      let validate = true

      // Emergency
      for (let i = 0; i < this.emergency_contact_list.length; i++) {
        let data = this.emergency_contact_list[i]
        if (
          !globalFunc.DataValid(data.name) &&
          !globalFunc.DataValid(data.mobile) &&
          !globalFunc.DataValid(data.email) &&
          !globalFunc.DataValid(data.relationship)
        ) {
          continue
        }

        if (globalFunc.DataValid(data.mobile)) {
          if (!CheckInputValidate.mobile(data.mobile)) {
            this.emergency_contact_list[i].error.mobile = `${this.$t('error.invalid')} ${this.$t(
              'worker.mobile_number',
            )} ${this.$t('error.format')}`
            validate = false
          } else {
            this.emergency_contact_list[i].error.mobile = ''
          }
        }

        if (globalFunc.DataValid(data.email)) {
          if (!CheckInputValidate.email(data.email)) {
            this.emergency_contact_list[i].error.email = `${this.$t('error.invalid')} ${this.$t(
              'worker.email',
            )} ${this.$t('error.format')}`
            validate = false
          } else {
            this.emergency_contact_list[i].error.email = ''
          }
        }
      }

      // Social Support
      if (this.service_selection.social_support && this.service_social_support.length == 0) {
        validate = false
        this.service_selection_error.social_support = true
      } else if (!this.service_selection.social_support && this.service_social_support.length != 0) {
        if (!this.service_social_support.includes('Work Experience') || this.service_social_support.length < 2) {
          validate = false
          this.service_selection_error.social_support = true
        } else {
          this.service_selection_error.social_support = false
        }
      } else {
        this.service_selection_error.social_support = false
      }

      for (let i = 0; i < this.service_social_support.length; i++) {
        let index = this.service_social_support_field.findIndex(obj => {
          return obj.value == this.service_social_support[i]
        })

        if (index != -1) {
          if (
            this.service_social_support_field[index].upload_required &&
            !globalFunc.DataValid(this.service_social_support_field[index].file)
          ) {
            this.service_social_support_field[index].error = true
            validate = false
          }
        }
      }

      // Personal Care
      if (this.service_selection.personal_care && this.service_personal_care.length < 2) {
        validate = false
        this.service_selection_error.personal_care = true
      } else if (!this.service_selection.personal_care && this.service_personal_care.length != 0) {
        if (
          !this.service_personal_care.includes('1 or more years of experience') ||
          this.service_personal_care.length < 2
        ) {
          validate = false
          this.service_selection_error.personal_care = true
        } else {
          this.service_selection_error.personal_care = false
        }
      } else {
        this.service_selection_error.personal_care = false
      }

      for (let i = 0; i < this.service_personal_care.length; i++) {
        let index = this.service_personal_care_field.findIndex(obj => {
          return obj.value == this.service_personal_care[i]
        })

        if (index != -1) {
          if (
            this.service_personal_care_field[index].upload_required &&
            !globalFunc.DataValid(this.service_personal_care_field[index].file)
          ) {
            this.service_personal_care_field[index].error = true
            validate = false
          }
        }
      }

      if (this.service_selection.personal_care && this.service_personal_care_two.length < 1) {
        validate = false
        this.service_selection_error.personal_care_two = true
      } else {
        this.service_selection_error.personal_care_two = false
      }

      for (let i = 0; i < this.service_personal_care_two.length; i++) {
        let index = this.service_personal_care_field_two.findIndex(obj => {
          return obj.value == this.service_personal_care_two[i]
        })

        if (index != -1) {
          if (
            this.service_personal_care_field_two[index].upload_required &&
            !globalFunc.DataValid(this.service_personal_care_field_two[index].file)
          ) {
            this.service_personal_care_field_two[index].error = true
            validate = false
          }
        }
      }

      if (!this.service_selection.personal_care) {
        if (this.service_personal_care.length != 0 && this.service_personal_care_two.length < 1) {
          this.service_selection_error.personal_care_two = true
          validate = false
        } else if (this.service_personal_care.length < 2 && this.service_personal_care_two.length != 0) {
          this.service_selection_error.personal_care = true
          validate = false
        } else {
          this.service_selection_error.personal_care = false
        }
      }

      // Nursing Registration
      if (
        this.service_selection.nursing_registration ||
        globalFunc.DataValid(this.service_nursing_registration.nursing_type.type)
      ) {
        if (!globalFunc.DataValid(this.service_nursing_registration.nursing_type.nursing_type_doc)) {
          this.service_nursing_registration.nursing_type.file_err = true
          validate = false
        } else {
          this.service_nursing_registration.nursing_type.file_err = false
        }

        if (!globalFunc.DataValid(this.service_nursing_registration.registration_number)) {
          this.service_nursing_registration.registration_error = true
          validate = false
        } else {
          this.service_nursing_registration.registration_error = false
        }

        if (!globalFunc.DataValid(this.nursing_expiry_date)) {
          this.nursing_expiry_date_error = true
          validate = false
        } else {
          this.nursing_expiry_date_error = false
        }

        if (!globalFunc.DataValid(this.service_nursing_registration.work_experience.work_exp_doc)) {
          this.service_nursing_registration.work_experience.file_err = true
          validate = false
        } else {
          this.service_nursing_registration.work_experience.file_err = false
        }

        if (this.nursing_checkbox.length == 0) {
          this.service_selection_error.nursing_registration = true
          validate = false
        } else {
          this.service_selection_error.nursing_registration = false
        }

        for (let i = 0; i < this.nursing_checkbox.length; i++) {
          let index = this.service_nursing_registration.checkbox.findIndex(obj => {
            return obj.value == this.nursing_checkbox[i]
          })

          if (index != -1) {
            if (
              this.service_nursing_registration.checkbox[index].upload_required &&
              !globalFunc.DataValid(this.service_nursing_registration.checkbox[index].file)
            ) {
              this.service_nursing_registration.checkbox[index].error = true
              validate = false
            }
          }
        }
      }

      // Allied Health Service
      if (this.service_selection.allied_health && this.allied_health.length < 1) {
        this.service_selection_error.allied_health = true
        validate = false
      } else {
        this.service_selection_error.allied_health = false
      }

      for (let i = 0; i < this.allied_health.length; i++) {
        let index = this.service_allied_health_field.findIndex(obj => {
          return obj.value == this.allied_health[i]
        })

        if (index != -1) {
          if (!globalFunc.DataValid(this.service_allied_health_field[index].registration_number)) {
            this.service_allied_health_field[index].registration_number_error = true
            validate = false
          } else {
            this.service_allied_health_field[index].registration_number_error = false
          }

          if (!globalFunc.DataValid(this.allied_health_date[index])) {
            this.service_allied_health_field[index].expiry_date_error = true
            validate = false
          } else {
            this.service_allied_health_field[index].expiry_date_error = false
          }

          if (
            this.service_allied_health_field[index].upload_required &&
            !globalFunc.DataValid(this.service_allied_health_field[index].file)
          ) {
            this.service_allied_health_field[index].error = true
            validate = false
          }

          if (
            this.service_allied_health_field[index].upload_required &&
            !globalFunc.DataValid(this.service_allied_health_field[index].work_experience.file)
          ) {
            this.service_allied_health_field[index].work_experience.error = true
            validate = false
          }
        }
      }

      // Additional Training
      if (this.service_selection.additional_training && this.additional_training.length < 1) {
        this.service_selection_error.additional_training = true
        validate = false
      } else {
        this.service_selection_error.additional_training = false
      }

      for (let i = 0; i < this.additional_training.length; i++) {
        let index = this.service_additional_training_field.findIndex(obj => {
          return obj.value == this.additional_training[i]
        })

        if (index != -1) {
          if (
            this.service_additional_training_field[index].upload_required &&
            !globalFunc.DataValid(this.service_additional_training_field[index].file)
          ) {
            this.service_additional_training_field[index].error = true
            validate = false
          }
        }
      }

      // rates and availability
      if (this.rates_availability.weekday.checked) {
        if (globalFunc.DataValid(this.rates_availability.weekday.rate)) {
          this.rates_availability.weekday.rate_error = false

          if (!CheckInputValidate.isFloating(this.rates_availability.weekday.rate)) {
            this.rates_availability.weekday.error_msg = `${this.$t('error.force_input')} ${this.$t(
              'error.floating',
            )} ${this.$t('error.only')}`
            validate = false
          } else {
            this.rates_availability.weekday.error_msg = ''
          }
        } else {
          this.rates_availability.weekday.rate_error = true
          this.rates_availability.weekday.error_msg = ''
          validate = false
        }
      } else {
        this.rates_availability.weekday.rate_error = false
        this.rates_availability.weekday.error_msg = ''
      }

      if (this.rates_availability.weekend.checked) {
        if (globalFunc.DataValid(this.rates_availability.weekend.rate)) {
          this.rates_availability.weekend.rate_error = false

          if (!CheckInputValidate.isFloating(this.rates_availability.weekend.rate)) {
            this.rates_availability.weekend.error_msg = `${this.$t('error.force_input')} ${this.$t(
              'error.floating',
            )} ${this.$t('error.only')}`
            validate = false
          } else {
            this.rates_availability.weekend.error_msg = ''
          }
        } else {
          this.rates_availability.weekend.rate_error = true
          this.rates_availability.weekend.error_msg = ''
          validate = false
        }
      } else {
        this.rates_availability.weekend.rate_error = false
        this.rates_availability.weekend.error_msg = ''
      }

      if (this.rates_availability.shift.checked) {
        if (globalFunc.DataValid(this.rates_availability.shift.rate)) {
          this.rates_availability.shift.rate_error = false

          if (!CheckInputValidate.isFloating(this.rates_availability.shift.rate)) {
            this.rates_availability.shift.error_msg = `${this.$t('error.force_input')} ${this.$t(
              'error.floating',
            )} ${this.$t('error.only')}`
            validate = false
          } else {
            this.rates_availability.shift.error_msg = ''
          }
        } else {
          this.rates_availability.shift.rate_error = true
          this.rates_availability.shift.error_msg = ''
          validate = false
        }
      } else {
        this.rates_availability.shift.rate_error = false
        this.rates_availability.shift.error_msg = ''
      }

      // Work History
      for (let i = 0; i < this.work_history_list.length; i++) {
        let data = this.work_history_list[i]

        if (
          !globalFunc.DataValid(data.job_title) &&
          !globalFunc.DataValid(data.company) &&
          !globalFunc.DataValid(this.work_start_date[i]) &&
          !globalFunc.DataValid(this.work_end_date[i])
        ) {
          continue
        }

        if (globalFunc.DataValid(this.work_start_date[i]) && globalFunc.DataValid(this.work_end_date[i])) {
          let start = this.work_start_date[i].split('-')
          let end = this.work_end_date[i].split('-')
          if (CheckInputValidate.compareYearMonth(start[0], start[1], end[0], end[1]) == 1) {
            this.work_history_list[i].error.start_date = this.$t('error.compare_date')
            this.work_history_list[i].error.end_date = this.$t('error.compare_date')
            validate = false
          } else {
            this.work_history_list[i].error.start_date = ''
            this.work_history_list[i].error.end_date = ''
          }
        } else {
          this.work_history_list[i].error.start_date = ''
          this.work_history_list[i].error.end_date = ''
        }
      }

      // Education
      for (let i = 0; i < this.education_training_list.length; i++) {
        let data = this.education_training_list[i]

        if (
          !globalFunc.DataValid(data.institution) &&
          !globalFunc.DataValid(data.degree_course) &&
          !globalFunc.DataValid(this.education_start_date[i]) &&
          !globalFunc.DataValid(this.education_end_date[i])
        ) {
          continue
        }

        if (globalFunc.DataValid(this.education_start_date[i]) && globalFunc.DataValid(this.education_end_date[i])) {
          let start = this.education_start_date[i].split('-')
          let end = this.education_end_date[i].split('-')
          if (CheckInputValidate.compareYearMonth(start[0], start[1], end[0], end[1]) == 1) {
            this.education_training_list[i].error.start_date = this.$t('error.compare_date')
            this.education_training_list[i].error.end_date = this.$t('error.compare_date')
            validate = false
          } else {
            this.education_training_list[i].error.start_date = ''
            this.education_training_list[i].error.end_date = ''
          }
        } else {
          this.education_training_list[i].error.start_date = ''
          this.education_training_list[i].error.end_date = ''
        }
      }

      // Badges
      if (this.covid == 'Yes, fully vaccinated' || this.covid == 'Yes, at least one dose') {
        if (!globalFunc.DataValid(this.covid_file)) {
          this.covid_error = true
          validate = false
        } else {
          this.covid_error = false
        }
      } else {
        this.covid_error = false
      }

      if (this.flu) {
        if (!globalFunc.DataValid(this.flu_file)) {
          this.flu_error = true
          validate = false
        } else {
          this.flu_error = false
        }
      } else {
        this.flu_error = false
      }

      // Verifications
      if (this.verifications.covid_training.checked) {
        if (globalFunc.DataValid(this.verifications.covid_training.covid_training_file)) {
          this.verifications.covid_training.error = false
        } else {
          this.verifications.covid_training.error = true
          validate = false
        }
      } else {
        this.verifications.covid_training.error = false
      }

      if (this.verifications.sexual_conviction_record.checked) {
        if (globalFunc.DataValid(this.verifications.sexual_conviction_record.sexual_conviction_record_file)) {
          this.verifications.sexual_conviction_record.file_error = false
        } else {
          this.verifications.sexual_conviction_record.file_error = true
          validate = false
        }

        if (globalFunc.DataValid(this.verifications.sexual_conviction_record.sexual_conviction_ref)) {
          this.verifications.sexual_conviction_record.ref_error = false
        } else {
          this.verifications.sexual_conviction_record.ref_error = true
          validate = false
        }

        if (globalFunc.DataValid(this.sexual_conviction_date)) {
          this.verifications.sexual_conviction_record.date_error = false
        } else {
          this.verifications.sexual_conviction_record.date_error = true
          validate = false
        }
      } else {
        this.verifications.sexual_conviction_record.file_error = false
        this.verifications.sexual_conviction_record.ref_error = false
        this.verifications.sexual_conviction_record.date_error = false
      }
      return validate
    },
    async saveChange() {
      let form_validate = this.$refs.form.validate()
      let custom_validate = this.customValidate()
      if (!form_validate || !custom_validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('error.fill_all_required_field'),
          type: 'error',
          refresh: false,
          redirect: '',
        })

        return
      }

      try {
        this.submitLoading = true
        let send_data = {
          id: parseInt(this.$route.params.id, 10),
          user_type: 'worker',
        }

        // Worker Account
        send_data.location = this.worker_account.location
        send_data.working_hour = this.worker_account.working_hour_support
        send_data.hear_from = this.worker_account.media_to_know
        send_data.status = this.worker_account.status
        send_data.user_approved = this.worker_account.user_approved

        // Worker Profile
        send_data.profile_pic = this.worker_profile.profile_pic
        send_data.first_name = this.worker_profile.first_name
        send_data.last_name = this.worker_profile.last_name
        send_data.nick_name = this.worker_profile.nickname
        send_data.gender = this.worker_profile.gender
        send_data.mobile = this.worker_profile.mobile
        send_data.addr = this.worker_profile.residential_addr
        send_data.post_addr = this.worker_profile.postal_addr
        send_data.bio = this.worker_profile.bio
        send_data.birthday = this.date_of_birth

        // Emergency
        let emergency = []
        for (let i = 0; i < this.emergency_contact_list.length; i++) {
          let data = this.emergency_contact_list[i]
          if (
            !globalFunc.DataValid(data.name) &&
            !globalFunc.DataValid(data.mobile) &&
            !globalFunc.DataValid(data.email) &&
            !globalFunc.DataValid(data.relationship)
          ) {
            continue
          }

          let temp = {
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            relationship: data.relationship,
          }

          if (globalFunc.DataValid(data.id)) {
            temp.id = data.id
          }

          emergency.push(temp)
        }

        send_data.emergency = emergency

        // Social Support
        send_data.ssda_items = this.service_social_support

        let index = this.service_social_support_field.findIndex(obj => {
          return obj.value == 'Provide Transport'
        })

        if (index != -1) {
          send_data[this.getFileName('ssda', 'Provide Transport')] = this.service_social_support_field[index].file
        }

        index = this.service_social_support_field.findIndex(obj => {
          return obj.value == 'Work Experience'
        })

        if (index != -1) {
          send_data[this.getFileName('ssda', 'Work Experience')] = this.service_social_support_field[index].file
        }

        // Personal Care
        send_data.personal_care_items = this.service_personal_care
        for (let i = 0; i < this.service_personal_care_field.length; i++) {
          if (this.service_personal_care.includes(this.service_personal_care_field[i].value)) {
            if (this.service_personal_care_field[i].upload_required) {
              let file = this.getFileName('personal_care', this.service_personal_care_field[i].value)
              if (globalFunc.DataValid(file)) {
                send_data[file] = this.service_personal_care_field[i].file
              }
            }
          }
        }

        // Personal Care 2
        send_data.personal_service = this.service_personal_care_two

        for (let i = 0; i < this.service_personal_care_field_two.length; i++) {
          if (this.service_personal_care_two.includes(this.service_personal_care_field_two[i].value)) {
            if (this.service_personal_care_field_two[i].upload_required) {
              let file = this.getFileName('personal_care', this.service_personal_care_field_two[i].value)
              if (globalFunc.DataValid(file)) {
                send_data[file] = this.service_personal_care_field_two[i].file
              }
            }
          }
        }

        // Nursing Registration
        if (globalFunc.DataValid(this.service_nursing_registration.nursing_type.type)) {
          send_data.nursing_type = this.service_nursing_registration.nursing_type.type
          send_data.nursing_type_file = this.service_nursing_registration.nursing_type.nursing_type_doc
          send_data.nursing_reg_no = this.service_nursing_registration.registration_number
          send_data.nursing_expiry_date = this.nursing_expiry_date
          send_data.nursing_exp_file = this.service_nursing_registration.work_experience.work_exp_doc

          send_data.nursing_care_item = this.nursing_checkbox
          for (let i = 0; i < this.service_nursing_registration.checkbox.length; i++) {
            if (this.nursing_checkbox.includes(this.service_nursing_registration.checkbox[i].value)) {
              if (this.service_nursing_registration.checkbox[i].upload_required) {
                let file = this.getFileName('nursing', this.service_nursing_registration.checkbox[i].value)
                if (globalFunc.DataValid(file)) {
                  send_data[file] = this.service_nursing_registration.checkbox[i].file
                }
              }
            }
          }
        }

        // Allied Health Service
        let allied_prefix = ['allied_ot', 'allied_p', 'allied_ps', 'allied_st', 'allied_sw']
        for (let i = 0; i < this.service_allied_health_field.length; i++) {
          if (this.allied_health.includes(this.service_allied_health_field[i].value)) {
            send_data[`${allied_prefix[i]}_health`] = true
            send_data[`${allied_prefix[i]}_reg_no`] = this.service_allied_health_field[i]['registration_number']
            send_data[`${allied_prefix[i]}_expiry_date`] = this.allied_health_date[i]
            send_data[`${allied_prefix[i]}_type_file`] = this.service_allied_health_field[i].file
            send_data[`${allied_prefix[i]}_exp_file`] = this.service_allied_health_field[i].work_experience.file
          } else {
            send_data[`${allied_prefix[i]}_health`] = false
          }
        }

        // Additional Training
        let additional_training_prefix = ['first_aid', 'manual_handing_cert', 'driving_licence']
        for (let i = 0; i < this.service_additional_training_field.length; i++) {
          if (this.additional_training.includes(this.service_additional_training_field[i].value)) {
            send_data[`${additional_training_prefix[i]}`] = true
            send_data[`${additional_training_prefix[i]}_file`] = this.service_additional_training_field[i].file
          } else {
            send_data[`${additional_training_prefix[i]}`] = false
          }
        }

        //Verifications
        send_data.verif_first_name = this.verifications.first_name
        send_data.verif_last_name = this.verifications.last_name
        send_data.verif_know_referee = this.verifications.know_referee
        send_data.verif_company = this.verifications.company
        send_data.verif_known_long = this.verifications.known_long
        send_data.verif_mobile = this.verifications.mobile
        send_data.verif_email = this.verifications.email
        send_data.covid_training = this.verifications.covid_training.checked
        send_data.covid_training_file = this.verifications.covid_training.covid_training_file
        send_data.sexual_conviction_record = this.verifications.sexual_conviction_record.checked
        send_data.sexual_conviction_record_file =
          this.verifications.sexual_conviction_record.sexual_conviction_record_file
        send_data.sexual_conviction_ref = this.verifications.sexual_conviction_record.sexual_conviction_ref
        send_data.sexual_conviction_date = this.sexual_conviction_date

        // Bank Account
        send_data.bank_ac_name = this.bank_acc.account_name
        send_data.bank_name = this.bank_acc.bank_name
        send_data.bank_ac_no = this.bank_acc.account_number

        // Rates and availability
        send_data.ava_for_work = this.rates_availability.avail_for_work == 'Yes' ? true : false
        send_data.location_for_work = this.rates_availability.location
        send_data.preferred_days = this.rates_availability.preferred_days
        send_data.meet_greet = this.rates_availability.meet_greet == 'Yes' ? true : false
        send_data.weekday_rate = this.rates_availability.weekday.checked
        if (this.rates_availability.weekday.checked) {
          send_data.weekday_rate_price = parseFloat(this.rates_availability.weekday.rate)
        }
        send_data.weekend_rate = this.rates_availability.weekend.checked
        if (this.rates_availability.weekend.checked) {
          send_data.weekend_rate_price = parseFloat(this.rates_availability.weekend.rate)
        }
        send_data.shift_rate = this.rates_availability.shift.checked
        if (this.rates_availability.shift.checked) {
          send_data.shift_rate_price = parseFloat(this.rates_availability.shift.rate)
        }

        // Work History
        let work = []
        for (let i = 0; i < this.work_history_list.length; i++) {
          let data = this.work_history_list[i]
          if (
            !globalFunc.DataValid(data.job_title) &&
            !globalFunc.DataValid(data.company) &&
            !globalFunc.DataValid(this.work_start_date[i]) &&
            !globalFunc.DataValid(this.work_end_date[i])
          ) {
            continue
          }

          let temp = {
            title: data.job_title,
            company: data.company,
            start_date: this.work_start_date[i],
            end_date: this.work_end_date[i],
            curr: data.current_working,
          }

          if (globalFunc.DataValid(data.id)) {
            temp.id = data.id
          }

          work.push(temp)
        }

        send_data.work_history = work

        // Education
        let education = []
        for (let i = 0; i < this.education_training_list.length; i++) {
          let data = this.education_training_list[i]
          if (
            !globalFunc.DataValid(data.institution) &&
            !globalFunc.DataValid(data.degree_course) &&
            !globalFunc.DataValid(this.education_start_date[i]) &&
            !globalFunc.DataValid(this.education_end_date[i])
          ) {
            continue
          }

          let temp = {
            institution: data.institution,
            degree: data.degree_course,
            start_date: this.education_start_date[i],
            end_date: this.education_end_date[i],
            curr: data.current_working,
          }

          if (globalFunc.DataValid(data.id)) {
            temp.id = data.id
          }

          education.push(temp)
        }

        send_data.education = education

        // Care Experience
        send_data.aged_care = this.care_experience_aged_care
        send_data.chronic_medical = this.care_experience_chronic
        send_data.disability = this.care_experience_disability
        send_data.mental_health = this.care_experience_mental_health

        // Badges
        send_data.covid_vaccine = this.covid
        if (this.covid == 'Yes, fully vaccinated' || this.covid == 'Yes, at least one dose') {
          send_data.covid_vaccine_file = this.covid_file
        }

        send_data.flu_vaccine = this.flu
        if (this.flu) {
          send_data.flu_vaccine_file = this.flu_file
        }

        send_data.lgbti = this.lgbti

        send_data.first_language = this.first_language
        send_data.cultural = this.cultural_background
        send_data.other_languages = this.other_language
        send_data.religion = this.religion
        send_data.hobbies = this.interest
        send_data.personality_at_work = this.personality
        send_data.non_smoker = this.non_smoker == 'Yes' ? true : false
        send_data.pet_friendly = this.pet_friendly == 'Yes' ? true : false
        send_data.preference_work_with = this.prefer_work_with
        send_data.preference_gender = this.prefer_gender

        let params = new URLSearchParams()
        params.set('update_user', JSON.stringify(send_data))
        let update_user = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: false,
          redirect: 'Worker',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
  },

  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.$store.dispatch('toggleLoadingPage', true)
      this.max_birth_date = globalFunc.formatDate(new Date())
      this.initializeStatus()
      this.initializeServiceField()
      this.initializeCareExperienceField()
      this.initializeBadgesField()
      this.initializeData()
    }
  },
}
</script>

<style lang="scss" scoped>
.expansion-card {
  &:hover {
    cursor: pointer;
    background-color: rgb(240, 239, 239);
    transition: 0.3s;
  }
}
</style>